import React from 'react'
import PropTypes from 'prop-types'
import ComingSoon from './comingSoon'
import TitleMain from './titleMain';
import axios from 'axios';
import {Route} from 'react-router-dom';
//components
import ShoppingCard from './shoppingCard';
import OrderList from './orderList';
import TakeOrder from './takeOrder';
import InquiryForm from './inquiryForm';
//data
import kit from '../data/kit';
import modules from './modules';
//css
import '../assets/css/order.css';

//store
import store from '../store/orderStore';
import inquiryStore from '../store/inquiryStore';
import {observer} from 'mobx-react';

//config
import config from '../config';

@observer
class Order extends React.Component {
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render () {
    return(
      <div className="Order">
        <div className="Order__title">
          <TitleMain name='採購'/>
        </div>
        <InquiryForm store={inquiryStore}/>
        {/*<div className="Order__shoppingCard">
          <ShoppingCard img={kit[0].img} title={kit[0].name} lists={kit[0].lists} modules={kit[0].modules} store={store} price='450' bgc={'#FF7F66'}/>
          <ShoppingCard img={kit[1].img} title={kit[1].name} lists={kit[1].lists} modules={kit[1].modules} store={store} price='450' bgc={'#7ECEFD'}/>
          <ShoppingCard img={kit[2].img} title={kit[2].name} lists={kit[2].lists} modules={kit[2].modules} store={store} price='450' bgc={'#502868'}/>
          <ShoppingCard img={kit[3].img} title={kit[3].name} lists={kit[3].lists} modules={kit[3].modules} store={store} price='450' bgc={'#57AF6D'}/>
          {Object.keys(modules).map((x,y)=>Object.keys(modules[x]).map(i=>
            //avoid temp modules
            //avoid Env module (Output) (due to duplicate issue)
            x == 't' || i == 'o5'?
            ''
            :<ShoppingCard img={modules[x][i].img} title={modules[x][i].name} modules={modules[x][i].name} store={store} price='200' bgc={'#5A7493'}/>
          ))}
        </div>
        <div className="Order__list">
          <OrderList store={store}/>
        </div>*/}
        {/*<div className='Order__contact'>
        <div></div>
        <h2 style={{width:'80%',margin:'50px auto'}}>套件採購請聯繫以下郵箱</h2>
        <div></div>
        <div></div>
        <h5><a href='mailTo:support-hk@cocorobo.cc'>support-hk@cocorobo.cc</a><span style={{marginLeft:'20px'}}></span></h5>
        </div>*/}
      </div>
    )
  }
}

export default Order;
