import React, {Component} from 'react';
import TitleMain from './titleMain';
import Demo from './demo';
import Button from './button';
import Combination from  './combination';
import TwoDemo from '../components/twoDemo';
import TwoCombination from '../components/twocombination'
// plugin
import scrollToComponent from 'react-scroll-to-component';

class CocoMod extends Component{
	componentDidMount(){
		document.getElementById('scrollToCombination').addEventListener('click',this.scrollTo.bind(this));
		document.getElementById('twoscrollToCombination').addEventListener('click',this.scrollTo.bind(this));
	}
	scrollTo(){
		scrollToComponent(this.Combination, { offset: 0, align: 'top', duration: 300, ease:'inCirc'});
		scrollToComponent(this.TwoCombination, { offset: 0, align: 'top', duration: 300, ease:'inCirc'});
	}
	render(){
		return(
			<div>
				{/*<TitleMain name='CocoMod &#8482; 模組' />*/}
				<Demo store={this.props.store}/>
				<Button name='去搭配看看' id='scrollToCombination'/>
				<Combination ref={(section) => { this.Combination = section; }}/>
				<TwoDemo></TwoDemo>
				<Button name='去搭配看看' id='twoscrollToCombination'/>
				<TwoCombination ref={(section) => { this.TwoCombination = section; }}/>

			</div>
		)
	}
}
export default CocoMod;