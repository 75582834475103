import React from 'react'
import PropTypes from 'prop-types'
import TitleMain from './titleMain'
import config from '../config';
//components
import QueryItem from './queryItem';
//css
import '../assets/css/orderQuery.css';
//ajax
import axios from 'axios';


class OrderQuery extends React.Component {
  constructor(props){
    super(props);
    this.state={
      order:'',
      invoice:'',
    }
    this.getInvoice = this.getInvoice.bind(this);
  }
  componentWillMount(){
    this.fetchOrder();
  }
  fetchOrder(){
    var self = this;
    axios.get(config.server+'/api/order')
    .then(function(response){
      self.setState({
        order:response.data
      })
    })
    .catch(function(error){
      console.log(error);
    })
  }
  getInvoice(event){
    let url = config.server+'/invoice/'+event.target.parentNode.parentNode.parentNode.children[0].innerHTML+'/'+event.target.parentNode.parentNode.parentNode.children[2].innerHTML;
    this.setState({
      invoice:url,
    })
  }
  convertTime(isoDate){
    let localeDate = new Date(isoDate);
    let date = localeDate.toLocaleString();
    return date;
  }
  render () {
    return(
      <div>
        <TitleMain name='訂單查詢'/>
        <div className="OrderQuery">
          <table className="OrderQuery__table">
            <tbody>
            <tr>
              <th>訂單編號</th>
              <th>訂單日期</th>
              <th>學校名稱</th>
              <th>訂單金額</th>
              <th>狀態</th>
              <th>操作</th>
            </tr>
            {Object.keys(this.state.order).map((x)=>
              <tr className='OrderQuery__cell'>
                <td>{this.state.order[x].sequence}</td>
                <td>{this.convertTime(this.state.order[x].date)}</td>
                <td>{this.state.order[x].name}</td>
                <td>{this.state.order[x].amount}</td>
                <td>成功</td>
                <td>
                  <a href={this.state.invoice} target='_blank'>
                    <button onClick={this.getInvoice}>下載報價單</button>
                  </a>
                </td>
              </tr>
            )}
            </tbody>
          </table>
          <div className="OrderQuery__item">
            {/*<QueryItem />*/}
          </div>
        </div>
      </div>
    )
  }
}

export default OrderQuery;
