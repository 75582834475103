import React from 'react'
import '../assets/css/queryItem.css';
//components
import Button from './button';

class QueryItem extends React.Component {
  render () {
    return(
      <div className='QueryItem'>
        <h5 className='QueryItem__num'>單號:A000123</h5>
        <h5 className='QueryItem__status'>成功</h5>
        <p className='QueryItem__title'>創意電子</p>
        <p className='QueryItem__quantity'>1</p>
        <p className='QueryItem__subTotal'>199</p>
        <p className='QueryItem__college'>保良局PLK</p>
        <div className='QueryItem__quotation'>
          <Button name='下載報價單'/>
        </div>
      </div>
    )
  }
}

export default QueryItem;
