import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/register.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
//router
import {Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';
//config
import config from '../config';
//plugin
import Recaptcha from 'react-recaptcha';
//material
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
//geetest
import Geetest from 'react-geetest';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '86%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


@observer
class Register extends React.Component {
  constructor(props){
    super(props);
    this.toggleMask = this.toggleMask.bind(this);
    this.register = this.register.bind(this);
    this.state={
      auth:false,
      reCapchaToken:'',
      geetest:false,
      gt:'',
      challenge:'',
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: '',
      prompt:'',
      registerPress: false
    }
  }
  componentDidMount(){
    //googleSignIn
    if(this.props.store.username !== ''){
      document.getElementById('Register__input--username').value = this.props.store.username;
    }
    this.geetestRegister();
  }
  postMessageToParent(id,status,data){
    //TODO: replace origin
    let result = {
      id,
      status,
      data
    }
    window.parent.postMessage(result,'*')
  }
  toggleMask(event){
    event.target.classList.toggle('Register__unmask');
    if(document.getElementById('Register__input--pwd').type === 'password'){
      document.getElementById('Register__input--pwd').type='text';
    }else{
      document.getElementById('Register__input--pwd').type='password';
    }
  }
  async register(){
    // await this.greCaptcha();
    //validation input is not empty
    
    let name = document.getElementById('Register__input--username').value.toLowerCase();
    let pwd = document.getElementById('Register__input--pwd').value;
    // if(name.length >= 3 && pwd.length >= 6){

    if(name.length >= 5 && this.checkPassword(pwd) && this.checkUsername(name)){
      this.setState({
        prompt:'',
        registerPress: true,
      })
      this.props.store.isLoading = true;
      let self = this;
      axios.defaults.withCredentials = true;
      axios.post(config.server+'/api/user', {
      username: document.getElementById('Register__input--username').value.toLowerCase(),
      password: document.getElementById('Register__input--pwd').value,
      college:document.getElementById('Register__input--college').value,
      identity:document.getElementById('Register__input--identity').options[document.getElementById('Register__input--identity').selectedIndex].value,
      googleId:self.props.store.googleId,
      grecaptcha:this.state.reCapchaToken,
      geetest_challenge: this.state.geetest_challenge,
      geetest_validate: this.state.geetest_validate,
      geetest_seccode: this.state.geetest_seccode
    })
    .then(function (response) {
      if(response.data == 'OK'){
        window.alert('註冊成功');
        self.props.store.isLoading = false;
        self.props.store.username = document.getElementById('Register__input--username').value;
        self.props.store.alias = self.props.store.username.slice(0,self.props.store.username.indexOf('@'));
        self.props.store.login = true;
        axios.get(`${config.server}/api/profile`)
          .then(function(res){
            self.postMessageToParent('loginVerify','logged',res.data);
            self.props.store.isLoading = false;
            self.setState({
              registerPress: false
            })
          })
          .catch(function(err){
            console.log(err);
            self.props.store.isLoading = false;
          })
      }else if(response.data.code == '11000'){
        document.querySelectorAll('.Register__prompt')[0].innerHTML='用戶名已註冊';
        self.props.store.isLoading = false;
      }else{
        console.log(response);
        self.props.store.isLoading = false;
      }
    })
    .catch(function(error){
      if(error.response.data == 'reCaptcha verify fails.'){
        self.setState({geetest:true,prompt:'請點擊驗證',registerPress: false})
      }
    })
  }else if(!this.checkUsername(name)){
    document.querySelectorAll('.Register__prompt')[0].innerHTML='請輸入有效的郵箱地址';
  }else if(name.length >=3 && pwd.length < 6){
    document.querySelectorAll('.Register__prompt')[0].innerHTML='密碼長度需大於6位';
  }else if(name.length >=3 && !this.checkPassword(pwd)){
    document.querySelectorAll('.Register__prompt')[0].innerHTML='密碼種類需包含兩種以上(數字及英文)';
  }
  }

  returnLogin() {
    window.location.href = "/login";	
  }

  checkUsername(str){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(str.toLowerCase());
  }
  checkPassword(str){
    let reg = /^(?=.*\d)(?=.*[A-Za-z]).{6,}$/;
    return reg.test(str);
  }
  greCaptcha(){
    return new Promise((resolve,reject)=>{
      let self = this;
      window.grecaptcha.ready(function() {
         window.grecaptcha.execute(config.reCaptchaSiteKey, {action: 'signup'}).then(function(token) {
            self.setState({
              reCapchaToken: token
            });
            return resolve(true);
         });
       });
    });
  }
  geetestRegister(){
    let self = this;
    axios.get(`${config.server}/geetest/pc-geetest/register`)
      .then((res)=>{
        self.setState({
          challenge:res.data.challenge,
          gt:res.data.gt
        })
      })
      .catch((err)=>{
        console.log(err);
      })
  }
  geetestValidate(isSuccess){
    this.setState({
      geetest_challenge: isSuccess.geetest_challenge,
      geetest_validate: isSuccess.geetest_validate,
      geetest_seccode: isSuccess.geetest_seccode
    })
  }
  render () {
    if(this.props.store.login){
      return <Redirect to='/success' />
    }
    const { loading, success } = this.state;
    const { classes } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    return(
      <div className="Register">
        <div className='Register__account'>
          <span className='Register__title'>電子郵件</span>
          <input type='text' id='Register__input--username' className='Register__input'/>
          <p className='Register__prompt'>{this.state.prompt}</p>
        </div>
        <div className='Register__pwd'>
          <span className='Register__title'>密碼</span>
          <button className='Register__mask' onClick={this.toggleMask}></button>
          <input type='password' className='Register__input' id='Register__input--pwd' placeholder='至少6位數' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"/>
        </div>
        <div className='Register__college'>
          <span className='Register__title'>學校名稱</span>
          <input type='text' className='Register__input' id='Register__input--college' />
        </div>
        <div className='Register__identity'>
          <span className='Register__title'>身份</span>
          <select id='Register__input--identity' className='Register__input--identity' style={{border:'1px solid #000',backgroundColor:'#fff'}}>
            <option>老師</option>
            <option>學生</option>
          </select>
        </div>

        {this.state.registerPress
              ? <React.Fragment>
                <button className='Register__button--press' onClick={this.register}>註冊</button>
                <CircularProgress size={24} className={classes.buttonProgress} />
              </React.Fragment>
              : <button className='Register__button' onClick={this.register}>註冊</button>
            }
        
        <button className='Return__button' onClick={this.returnLogin}>返回</button>
        <div className='Register__recaptcha'>
          {this.state.gt && this.state.challenge && this.state.geetest
            ?  <Geetest
                gt={this.state.gt}
                challenge={this.state.challenge}
                onSuccess={this.geetestValidate.bind(this)}
                lang='zh-hk'
              />
            :null
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Register);
