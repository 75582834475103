import kit_keyboardPiano_bg from '../assets/img/kit_keyboardPiano_bg.png';
import kit_keyboardPiano from '../assets/img/website-08.png';
import kit_carLineTracking_bg from '../assets/img/kit_carLineTracking_bg.png';
import kit_carLineTracking from '../assets/img/website-07.png';
import kit_LEDMiniBox_bg from '../assets/img/kit_LEDMiniBox_bg.png';
import kit_LEDMiniBox from '../assets/img/kit_LEDMiniBox.png';
import kit_bipedRobotBasic_bg from '../assets/img/kit_bipedRobotBasic_bg.png';
import kit_bipedRobotBasic from '../assets/img/website-06.png';
import kit_weatherStation_bg from '../assets/img/kit_weatherStation_bg.png';
import kit_weatherStation from '../assets/img/website-05.png';
import kit_robotArm_bg from '../assets/img/kit_robotArm_bg.png';
import kit_robotArm from '../assets/img/kit_robotArm.png';
import kit_competitionKit from '../assets/img/website-16.png';
import kit_fullKit from '../assets/img/website-04.png';
import kit_bg from '../assets/img/kit_bg.png';
import video_keyboardPiano from '../assets/video/keyboardPiano.mp4';
import video_robotCarLineTracker from '../assets/video/robotCarLineTracker.mp4';
import video_LEDMiniBox from '../assets/video/LEDMiniBox.mp4';
import video_bipedRobot from '../assets/video/bipedRobot.mp4';
import video_weatherStaion from '../assets/video/weatherStation.mp4';
import video_robotArm from '../assets/video/robotArm.mp4';
import video_competitionKit from '../assets/video/competitionKit.mp4';
import video_fullKit from '../assets/video/fullKit.mp4';
import kit_musicKit from '../assets/img/website-12.png';
import kit_mechanicalKit from '../assets/img/website-15.png';
import kit_machineKit from '../assets/img/website-13.png';
import kit_powerKit from '../assets/img/website-14.png';
import kit_ledKit from '../assets/img/website-11.png';
import kit_WebKit from '../assets/img/website-09.png';
import kit_WebKits from '../assets/img/website-10.png';
import kit_NbiotKit from '../assets/img/website-07.png';
import kit_AientrylKit from '../assets/img/website-02.png';
import kit_AiotlKit from '../assets/img/website-03.png';
import kit_ailKit from '../assets/img/website-01.png';
import kit_AientrylKitVideo from '../assets/video/website-02.mp4';
import kit_noiotKitVideo from '../assets/video/website-08.mp4';
import kit_WebKitVideo from '../assets/video/website-09.mp4';
import kit_WebKitsVideo from '../assets/video/website-10.mp4';
import kit_ledKitVideo from '../assets/video/website-11.mp4';
import kit_musicKitVideo from '../assets/video/website-12.mp4';
import kit_machineKitVideo from '../assets/video/website-13.mp4';
import kit_mechanicalKitVideo from '../assets/video/website-15.mp4';

//更改记录
//Fri Jun 29 7:00 P.M  Source: Tony

const kit ={
	0:{
		name:'人工智能套件',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_ailKit,
		cat:'進階套件',
		video:'',
		id:'makerKit',
		unitPrice:450,
	},
	1:{
		name:'人工智能入門套件',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_AientrylKit,
		cat:'进阶套件',
		video:"",
		id:'makerKit',
		unitPrice:450,
	},
	2:{
		name:'AIoT 套件',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_AiotlKit,
		cat:'进阶套件',
		video:kit_AientrylKitVideo,
		id:'makerKit',
		unitPrice:450,
	},
	3:{
		name:'百變製造',
		nameEN:'Maker Kit',
		desc:'百變智造套件是由 CocoRobo 自主研發的造物套件，裏面包含所有功能的電子模組，可以互相組合滿足上百種功能需求，充分賦能於個體，方便學生針對問題進行解決方案設計。',
		modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		lists:['土壤溼度傳感器*1','雨滴傳感器*1','時鐘模組*1','氣敏傳感器*1','人體紅外傳感*1','超聲波傳感器*1','火焰傳感器*1','震動傳感器*1','紅外循跡傳感器*2','傾斜傳感器*1','光照傳感器*1','LED燈帶*1',' MicroUSB數據線*1','母對母杜邦線*2','TT電機*2','9g舵機*4','彩色卡紙*3'],
		bgImg:kit_fullKit,
		bgColor:'#55257c',
		img:kit_fullKit,
		cat:'进阶套件',
		video:video_fullKit,
		id:'makerKit',
		unitPrice:450,
	},
	4:{
		name:'智能家居',
		nameEN:'Smart Home Kit',
		desc:'通過學習本套件，學生將能夠製作迷你氣象站。學生可以使用迷你氣象站採集環境的溫度／溼度等數據，並在熒幕上顯示出來；同時可以通過Wi-Fi控制氣象站，並實現數據的傳輸。',
		modules:['m0','c0','i5','o2','a1','a3'],
		lists:['土壤溼度傳感器*1','雨滴傳感器*1','時鐘模組*1','氣敏傳感器*1','人體紅外傳感器*1','超聲波傳感器*1','火焰傳感器*1','震動傳感器*1','紅外置近傳感器*1','傾斜傳感器*1','光敏傳感器*1','USB數據線*1','杜邦線若干'],
		cat:'高級套件',
		bgImg:kit_weatherStation,
		bgColor:'#29a08f',
		img:kit_weatherStation,
		video:video_weatherStaion,
		id:'smartHomeKit',
		unitPrice:450,
	},
	5:{
		name:'機械達人',
		nameEN:'Robot Guru',
		desc:'通過學習本套件，學生將能夠製作一款百變雙足機器人，可以通過設置進程使機器人作出特定的動作，除此之外，還可以通過藍牙對它進行遠程操作。通過這門課學生亦可製作一款可通過藍牙操控的機械臂。',
		modules:['m0','o4','a2','a2','c1','a3','i0'],
		lists:['9克舵機*4','USB數據線*1','杜邦線若干','扳手螺絲套件','包裝盒'],
		bgImg:kit_bipedRobotBasic,
		bgColor:'#302c7e',
		img:kit_bipedRobotBasic,
		cat:'中級套件',
		video:video_bipedRobot,
		id:'robotGuru',
		unitPrice:450,
	},
	6:{
		name:'動力小車',
		nameEN:'Robot Car',
		desc:'通過學習本套件，學生將能夠製作多功能迷你小車。小車具有自動駕駛的功能，亦可通過感知前方是否有障礙物，來實現智能避障的功能，甚至可以通過藍牙控制小車。',
		modules:['m0','o3','i0','c1','a1','a1'],
		lists:['橡膠圈*2','TT電機*2','萬向輪*1','USB線*1','杜邦線若干','扳手螺絲套件','紅外傳感器','超聲波傳感器','包裝盒'],
		bgImg:kit_carLineTracking,
		bgColor:'#43a0d0',
		img:kit_carLineTracking,
		cat:'中级套件',
		video:video_robotCarLineTracker,
		id:'robotCar',
		unitPrice:450,
	},
	7:{
		name:'創意電子',
		nameEN:'Creative Electronics',
		desc:'本套件將帶領學生領略聲音和燈光的美麗。利用這個套件可製作出可仿真不同樂器的電子琴。亦可以製作一個絢麗的呼吸燈。亦可將二者結合，製作出一個既可以發出聲音也可以發出燈光的樂器。',
		modules:['m0','i2','o0','o1'],
		lists:['導電膠布*1','音頻線','小音箱','USB數據線*1','杜邦線若干','包裝盒'],
		bgImg:kit_keyboardPiano,
		bgColor:'#d83a37',
		img:kit_keyboardPiano,
		cat:'入门级套件',
		video:video_keyboardPiano,
		id:'creativeEletronics',
		unitPrice:450,
	},
	8:{
		name:'物聯網主題--NB-IoT',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		img:kit_WebKit,
		cat:'进阶套件',
		video:kit_noiotKitVideo,
		id:'makerKit',
		unitPrice:450,
	},
	9:{
		name:'物聯網主題--Wifi',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_WebKits,
		cat:'进阶套件',
		video:kit_WebKitVideo,
		id:'makerKit',
		unitPrice:450,
	},
	10:{
		name:'燈光主題',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_ledKit,
		cat:'进阶套件',
		video:kit_WebKitsVideo,
		id:'makerKit',
		unitPrice:450,
	},
	11:{
		name:'音樂主題',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_musicKit,
		cat:'进阶套件',
		video:kit_ledKitVideo,
		id:'makerKit',
		unitPrice:450,
	},
	12:{
		name:'人機交互主題',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_machineKit,
		cat:'进阶套件',
		video:kit_musicKitVideo,
		id:'makerKit',
		unitPrice:450,
	},
	13:{
		name:'動力主題',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_powerKit,
		cat:'进阶套件',
		video:kit_machineKitVideo,
		id:'makerKit',
		unitPrice:450,
	},
	14:{
		name:'機械主題',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_mechanicalKit,
		cat:'进阶套件',
		video:kit_mechanicalKitVideo,
		id:'makerKit',
		unitPrice:450,
	},
	15:{
		name:'3D 打印機器人比賽套件件',
		nameEN:'Competition Kit',
		desc:'開啓奇幻的魔盒，進行一場勇者之間的奪寶之旅。',
		modules:['t0','t1','t2','a1','a2','a3','t3','t4'],
		lists:['電機*4','9g舵機*2','金屬舵機*4','USB線(一分二)*1','輪胎膠圈*4','小車裝配用螺絲和螺帽*10','USB數據線*1','杜邦線*40','自封袋*1','包裝盒 *1'],
		cat:'比賽套件',
		bgImg:kit_competitionKit,
		bgColor:'#3C78BC',
		img:kit_competitionKit,
		video:video_competitionKit,
		id:'competitionKit',
	},
}

export default kit;
