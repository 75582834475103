import React, { Component } from 'react';
import '../assets/css/sliderFullscreen.css';
//import plugins
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';
import nl2br from 'react-newline-to-break';
import { Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';

//import images
import HomepageBg_1 from '../assets/img/homepageBg1.jpg';
import HomepageBg_2 from '../assets/img/homepageBg2.jpg';
import HomepageBg_3 from '../assets/img/homepageBg3.jpg';
import HomepageBg_4 from '../assets/img/homepageBg4.jpg';


class SliderFullscreen extends Component {

	render() {
		return (
			<div className='sliderFullscreen'>
				<Carousel
					autoplay
					width='100%'
					transitionMode='fade'
					wrapAround
					renderCenterLeftControls={({ previousSlide }) => (<div></div>)}
					renderCenterRightControls={({ previousSlide }) => (<div></div>)}
				>
					<div className='slide'>
						<img src={require('../assets/img/官网banner-二代AI.png')} alt="" width='100%' />
						<div className='content' style={{ top: '70%', left: '22%' }}>
							<Link to='/'>
								<button>點擊查看</button>
							</Link>
						</div>
					</div>
					<div className='slide'>
						<img src={HomepageBg_4} alt="" width='100%' />
						<div className='content' >
							<h1 style={{ lineHeight: '24px', fontSize: '3em' }}>Basic Kit</h1>
							<h2>入門套件</h2>
							<h2>小小模組 大大夢想</h2>
							<Link to='/cocokit'><button>點擊查看</button></Link>
						</div>
					</div>
					<div className='slide'>
						<img src={HomepageBg_1} alt="" width='100%' />
						<div className='content' >
							<h1>{nl2br('使用CocoMod\n快速實現你的想法')}</h1>
							<Link to='/create'><button>開始創造</button></Link>
						</div>
					</div>
					<div className='slide'>
						<img src={HomepageBg_2} alt="" width='100%' />
						<div className='content' >
							<h1>{nl2br('STEAM教育的\n一體化解決方案')}</h1>
							<Link to='/about'><button>關於我們</button></Link>
						</div>
					</div>
					<div className='slide'>
						<img src={HomepageBg_3} alt="" width='100%' />
						<div className='content' >
							<h1>{nl2br('與大家\n分享你的想法')}</h1>
							<Link to='/create'><button>開始探索</button></Link>
						</div>
					</div>
				</Carousel>
				{/*<div uk-slideshow="animation:fade; autoplay:true" className='uk-position-relative uk-grid-match uk-grid-small' uk-slider="true">
					<ul className="uk-slideshow-items uk-width-1-1" uk-height-viewport="true">
							<li>
									<img src={HomepageBg_4} alt="" uk-cover="true" />
									<div className='content' >
										<h1 style={{lineHeight:'24px',fontSize:'3em'}}>Basic Kit</h1>
										<h2>入門套件</h2>
										<h2>小小模組 大大夢想</h2>
										<Link to='/cocokit'><button>點擊查看</button></Link>
									</div>
							</li>
							<li>
									<img src={HomepageBg_1} alt="" uk-cover="true" />
									<div className='content' >
										<h1>{nl2br('使用CocoMod\n快速實現你的想法')}</h1>
										<Link to='/create'><button>開始創造</button></Link>
									</div>
							</li>
							<li>
									<img src={HomepageBg_2} alt="" uk-cover="true" />
									<div className='content' >
										<h1>{nl2br('STEAM教育的\n一體化解決方案')}</h1>
										<Link to='/about'><button>關於我們</button></Link>
									</div>
							</li>
							<li>
									<img src={HomepageBg_3} alt="" uk-cover="true" />
									<div className='content' >
										<h1>{nl2br('與大家\n分享你的想法')}</h1>
										<Link to='/create'><button>開始探索</button></Link>
									</div>
							</li>
					</ul>
					<ul className="uk-flex toggle uk-position-absolute" >
						<li uk-slideshow-item="0"><a href="#">套件</a></li>
		        <li uk-slideshow-item="1"><a href="#">創造</a></li>
		        <li uk-slideshow-item="2"><a href="#">設計</a></li>
		        <li uk-slideshow-item="3"><a href="#">啟發</a></li>
    			</ul>
				</div>*/}
			</div>
		)
	}
}
export default SliderFullscreen;
