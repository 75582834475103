import React , {Component} from 'react';
import '../assets/css/slider.css';
//import plugins
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';
import nl2br from 'react-newline-to-break';
import {Link} from 'react-router-dom';

//import images
import HomepageBg_1 from '../assets/img/homepageBg1.jpg';
import HomepageBg_2 from '../assets/img/homepageBg2.jpg';
import HomepageBg_3 from '../assets/img/homepageBg3.jpg';
import axios from 'axios';

class Slider extends Component{
	constructor(props){
		super(props)
		this.state={
			title:['',''],
			url:['','']
		}
	}
	componentDidMount(){
		axios.get('http://localhost:8080/api/feature')
		.then(res=> {
			let lastItem = res.data.length;
			this.setState({
				title:[res.data[lastItem-2].title,res.data[lastItem-1].title],
				url:[res.data[lastItem-2].url,res.data[lastItem-1].url]
			})
		}

		)
	}
	render(){
		return (
			<div uk-slideshow="animation: push;autoplay: true;" style={{width:'80%',margin:'50px auto'}} className='slider'>

		    <div className="uk-position-relative uk-visible-toggle uk-light">

		        <ul className="uk-slideshow-items" uk-height-viewport="offset-top: true; offset-bottom: 10">
		            <li>
		                <img src={HomepageBg_1} alt="editor_choice_1" uk-cover='true'/>
										<div className='content'>
											<h1>{this.state.title[0]}</h1>
											<a href={this.state.url[0]}><button>Learn More</button></a>
										</div>
		            </li>
		            <li>
		                <img src={HomepageBg_2} alt="editor_choice_1" uk-cover='true'/>
										<div className='content'>
											<h1>{this.state.title[1]}</h1>
											<a href={this.state.url[1]}><button>Learn more</button></a>
										</div>
		            </li>
		            <li>
		                <img src={HomepageBg_3} alt="editor_choice_1" uk-cover='true'/>
										<div className='content'>
											<h1>家用恆温控制器</h1>
											<button>Learn More</button>
										</div>
		            </li>
		        </ul>

		        <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous='true' uk-slideshow-item="previous"></a>
		        <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next='true' uk-slideshow-item="next"></a>
						<ul className="uk-slideshow-nav uk-dotnav"></ul>
		    </div>
				<div className=" uk-position-small">
			 		<ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin">
						<li uk-slideshow-item="0"><a href="#"></a></li>
						<li uk-slideshow-item="1"><a href="#"></a></li>
            <li uk-slideshow-item="2"><a href="#"></a></li>
					</ul>
	 	 		</div>
		</div>
		)
	}
}
export default Slider;
