
import MainModule from '../assets/img/m0.jpg';
import MainModuleOldVersion from '../assets/img/m1.jpg';

import Sensor101Module from '../assets/img/i0.jpg';
import MotionSensorModule from '../assets/img/i1.jpg';
import TouchSensorModule from '../assets/img/i2.jpg';
import AudioAnalyzerModule from '../assets/img/i3.jpg';
import ColorAnalyzerModule from '../assets/img/i4.jpg';
import EnvironmentalSensorMoudle from '../assets/img/i5.jpg';
import MusicModule from '../assets/img/o0.jpg';
import LedMatrixModule from '../assets/img/o1.jpg';
import OledScreenModule from '../assets/img/o2.jpg';
import MotorDriverModule from '../assets/img/o3.jpg';
import ServoHubModule from '../assets/img/o4.jpg';
import WifiModule from '../assets/img/c0.jpg';
import BluetoothModule from '../assets/img/c1.jpg';
import HorizontalAdapterModule from '../assets/img/a0.jpg';
import HubModuleA1A2Module from '../assets/img/a1.jpg';
import HubModuleB1B2Module from '../assets/img/a2.jpg';
import MaleMaleAdapterModule from '../assets/img/a3.jpg';
import BatterySupply from '../assets/img/a4.jpg';
//temp
import MainModuleX4Temp from '../assets/img/m0x4_temp.jpg';
import BluetoothModuleX4Temp from '../assets/img/c1x4_temp.jpg';
import Sensor101ModuleX4Temp from '../assets/img/i0x4_temp.jpg';
import MotorDriverModuleX2Temp from '../assets/img/o3x2_temp.jpg';
import ServoHubModuleX2Temp from '../assets/img/o4x2_temp.jpg';

import icon_m0 from '../assets/img/icon/module/m0.svg';
import icon_i0 from '../assets/img/icon/module/i0.svg';
import icon_i1 from '../assets/img/icon/module/i1.svg';
import icon_i2 from '../assets/img/icon/module/i2.svg';
import icon_i3 from '../assets/img/icon/module/i3.svg';
import icon_i4 from '../assets/img/icon/module/i4.svg';
import icon_i5 from '../assets/img/icon/module/i5.svg';
import icon_i6 from '../assets/img/icon/module/i6.svg';
import icon_o0 from '../assets/img/icon/module/o0.svg';
import icon_o1 from '../assets/img/icon/module/o1.svg';
import icon_o2 from '../assets/img/icon/module/o2.svg';
import icon_o3 from '../assets/img/icon/module/o3.svg';
import icon_o4 from '../assets/img/icon/module/o4.svg';
//clip
import clip_i0 from '../assets/video/01_sensor-101-module.mp4';
import clip_i1 from '../assets/video/11_motion-sensing-module.mp4';
import clip_i2 from '../assets/video/04_touch-sensing-module.mp4';
import clip_i5 from '../assets/video/06_environmental-sensing-module.mp4';

import clip_o0 from '../assets/video/03_music-module.mp4';
import clip_o1 from '../assets/video/02_led-matrix-module.mp4';
import clip_o3 from '../assets/video/07_motor-driver-module.mp4';
import clip_o4 from '../assets/video/08_servo-hub-module.mp4';
import clip_o5 from '../assets/video/06_environmental-sensing-module.mp4';

import clip_c1 from '../assets/video/10_bluetooth-module.mp4';
import clip_a0 from '../assets/video/14_hub-module.mp4';
import clip_a1 from '../assets/video/14_hub-module.mp4';
import clip_a2 from '../assets/video/14_hub-module.mp4';


// 主控
import m2ai from '../assets/img/ai-1.jpg';
import m2iot from '../assets/img/iot.jpg';
// 输出
import twoScreen from '../assets/img/screen-1.jpg';
import twoLed from '../assets/img/led.jpg';
// 输入
import twoSxt from '../assets/img/摄像头.jpg'
import twoCotroller from '../assets/img/controller.jpg';

// 转接
import twoExtnsion from '../assets/img/extension.jpg';
import two21 from '../assets/img/2-1.jpg'
import twoMotor from '../assets/img/motor.jpg'

//unitPrice by ST Jul 10 6:48 P.M




const modules = {
  m: {
    m0: {
      img: MainModule,
      name: '主機板模組',
      icon: icon_m0,
      desc: '通過對主板模組程式設計，用它控制其他所有模組的數據的輸入或輸出。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/main-controller',
      unitPrice: 200,
      // video:clip_m0,
    }
    // m1: {
    //     img: MainModuleOldVersion,
    //     name: '主控模組',
    // 		icon:icon_m0,
    // 		desc:'通過對主板模組程式設計，用它控制其他所有模組的數據的輸入或輸出。',
    //     wiki:'http://blockly-help.cocorobo.hk/',
    //     // video:clip_m1,
    // }
  },
  i: {
    i0: {
      img: Sensor101Module,
      name: '教學模組',
      match: ['o0', 'o1', 'o3', 'o5'],
      icon: icon_i0,
      desc: '教學板主要用於教學練習，幫助學生完成簡單的程式設計實驗，同時也可以用作感應器或者輸入介面。例如：利用教學板上的按鈕，與LED模組結合製作一個簡易的俄羅斯方塊遊戲機。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/sensor-101',
      video: clip_i0,
    },
    i1: {
      img: MotionSensorModule,
      name: '運動感應模組',
      match: ['o0', 'o1', 'o3'],
      icon: icon_i1,
      desc: '此模組能夠識別3軸加速度及角速度，提供運動姿態相關的數據。此模組可用於製作無人機或運動計步器。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/motion',
      video: clip_i1,
    },
    i2: {
      img: TouchSensorModule,
      name: '觸摸感應模組',
      match: ['o0', 'o1', 'o3'],
      icon: icon_i2,
      desc: '此模組為電容式觸摸模組，可以識別人體微弱電流，並提供多個信號輸入。此模組可結合音樂模組以製作迷你樂器。',
      video: clip_i2,
      wiki: 'https://help.cocorobo.hk/#/cocomod/touch',
    },
    i3: {
      img: AudioAnalyzerModule,
      name: '音頻分析模組',
      match: ['o1', 'o3'],
      icon: icon_i3,
      desc: '此模組可以將輸入的音頻文件轉換為相應的數據，結合LED模組可以實現隨音樂轉換的燈光效果。與LED模組、頻音樂模組結合可製作燈光頻率音響。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/audio-analyzer',
      // video:clip_i3,
    },
    i4: {
      img: ColorAnalyzerModule,
      name: '顏色分析模組',
      match: ['o0', 'o1'],
      icon: icon_i4,
      desc: '此模組提供色彩識別功能，能夠檢測到物體的色彩RGB值。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/color-analyzer',
      // video:clip_i4,
    },
    i5: {
      img: EnvironmentalSensorMoudle,
      name: '環境感應模組',
      match: ['o0', 'o1', 'o2', 'o3', 'o4'],
      icon: icon_i5,
      desc: '此模組可以檢測到環境的温度、濕度、光照強度、聲音強度值，同時又有蜂鳴器以及 RGB（256 色） LED 燈，可提供警報的功能。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/environment',
      video: clip_i5,
    },
    // i6: {
    //     img: WifiModule,
    //     name: 'WIFI模組',
    //     match: ['o1'],
    // 		icon:icon_i6,
    // 		desc:'Wi-Fi 無線通信模組在 CocoMods 負責進行數據的無線數據傳輸，支持 HTTP/WebSocket/MQTT 通訊協議，有了它，用户可以搭建屬於自己的物聯網小應用。',
    // },
    // i7: {
    //     img: BluetoothModule,
    //     name: '藍牙模組',
    //     match: ['o1'],
    // },

  },
  o: {
    o0: {
      img: MusicModule,
      name: '音樂模組',
      match: ['i0', 'i1', 'i2', 'i4', 'i5'],
      icon: icon_o0,
      desc: '此模組可用於播放音樂文件。例如製作一個簡單的MP3音樂播放器，或與觸摸板結合製作一個迷你電子琴。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/music',
      video: clip_o0,
    },
    o1: {
      img: LedMatrixModule,
      name: 'LED 燈屏模組',
      match: ['i0', 'i1', 'i2', 'i3', 'i4', 'i5', 'i6'],
      icon: icon_o1,
      desc: '此模組有提供5x5 彩色LED燈,每顆都可以獨立控制，供用戶自訂顯示模式。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/led-matrix',
      video: clip_o1,
    },
    o2: {
      img: OledScreenModule,
      name: '模組模組',
      match: ['i5'],
      icon: icon_o2,
      desc: '此模組可提供高解像度及高對比度的圖像顯示功能。與環境傳感模組結合可製作一個迷你氣象監測站',
      wiki: 'https://help.cocorobo.hk/#/cocomod/screen',
      // video:clip_o2,
    },
    o3: {
      img: MotorDriverModule,
      name: '馬達驅動模組',
      match: ['i0', 'i1', 'i2', 'i3', 'i5'],
      icon: icon_o3,
      desc: '此模組可以用於控制兩個直流馬達，並提供4個數據輸入口，方便連接各種感應器。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/motor-driver',
      video: clip_o3,
    },
    o4: {
      img: ServoHubModule,
      name: '伺服馬達轉接模組',
      match: ['i5'],
      icon: icon_o4,
      desc: '此模組提供內外部兩種電源驅動模式，方便多種舵機的同時使用。此模組可用於製作機械臂、雙足機器人等。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/servo',
      video: clip_o4,
    },
    o5: {
      img: EnvironmentalSensorMoudle,
      name: '環境感應模組',
      match: ['i0'],
      icon: icon_i5,
      desc: '此模組可以檢測到環境的温度、濕度、光照強度、聲音強度值，同時又有蜂鳴器以及 RGB（256 色） LED 燈，可提供警報的功能。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/environment',
      video: clip_o5,
    },
  },
  c: {
    c0: {
      img: WifiModule,
      name: 'Wi-Fi 通訊模組',
      desc: 'WiFi 無線通信模組可進行無線數據傳輸，支持 HTTP/WebSocket/MQTT 通訊協議。用户可以利用此模組搭建屬於自己的物聯網小應用。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/wifi',
      // video:clip_c0,
    },
    c1: {
      img: BluetoothModule,
      name: '藍牙通訊模組',
      desc: '全功能藍牙V2.0, 藍牙通信模組可以用在一些需要手機控制的應用。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/bluetooth',
      video: clip_c1,
    },
  },
  a: {
    a0: {
      img: HorizontalAdapterModule,
      name: '平行轉接模組',
      desc: '提供各類傳感器的拓展接口。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/horizontal-adapter',
      video: clip_a0,
    },
    a1: {
      img: HubModuleA1A2Module,
      name: '轉接模组 A',
      desc: '提供各類傳感器的拓展接口。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/hub',
      video: clip_a1,
    },
    a2: {
      img: HubModuleB1B2Module,
      name: '轉接模組 B',
      desc: '提供各類傳感器的拓展接口。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/hub',
      video: clip_a2,
    },
    a3: {
      img: MaleMaleAdapterModule,
      name: '正反轉接模組',
      desc: '提供模組的反向連接功能，滿足項目製作過程中的不同的場景應用。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/reversed-adapter',
      // video:clip_a3,
    },
  },
  //temp
  t: {
    t0: {
      img: MainModuleX4Temp,
      name: '主機板模組',
      icon: icon_m0,
      desc: '通過對主板模組程式設計，用它控制其他所有模組的數據的輸入或輸出。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/main-controller',
    },
    t1: {
      img: Sensor101ModuleX4Temp,
      name: '教學模組',
      match: ['o0', 'o1', 'o3', 'o5'],
      icon: icon_i0,
      desc: '教學板主要用於教學練習，幫助學生完成簡單的程式設計實驗，同時也可以用作感應器或者輸入介面。例如：利用教學板上的按鈕，與LED模組結合製作一個簡易的俄羅斯方塊遊戲機。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/sensor-101',
    },
    t2: {
      img: MotorDriverModuleX2Temp,
      name: '馬達驅動模組',
      match: ['i0', 'i1', 'i2', 'i3', 'i5'],
      icon: icon_o3,
      desc: '此模組可以用於控制兩個直流馬達，並提供4個數據輸入口，方便連接各種感應器。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/motor-driver',
    },
    t3: {
      img: ServoHubModuleX2Temp,
      name: '伺服馬達轉接模組',
      match: ['i5'],
      icon: icon_o4,
      desc: '舵機轉接模組提供內外部兩種電源驅動模式，方便多種舵機的同時使用。此模組可用於製作機械臂、雙足機器人等。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/servo',
    },
    t4: {
      img: BluetoothModuleX4Temp,
      name: '藍牙通訊模組',
      desc: '全功能藍牙V2.0, 藍牙通信模組可以用在一些需要手機控制的應用。',
      wiki: 'https://help.cocorobo.hk/#/cocomod/bluetooth'
    }
  },
  // others
  z: {
    z0: {
      img: BatterySupply,
      name: '電源管理模組',
      desc: '此模組能給主機板模組提供穩定的電壓，上有電源插座（杜邦線插座），可外接如 AA 電池盒或 9V 電池，模組上亦帶有撥動開關，可調節電源的開和關',
      wiki: 'https://help.cocorobo.hk/#/cocomod/power-management',
      // video:clip_z0,
    }
  },
  b:{
    b0: {
      img: m2ai,
      name: 'AI 模組',
      match: ['d0', 'd1', 'd2','d3','d4','d5'],
      icon: icon_m0,
      desc: 'AI 模組作爲人工智能相關應用的主控模組，擁有基礎計算機視覺識別功能和機器學習模型的推理功能，可幫助實現入門和進階級別的AI應用。模組主要應用於人工智能相關運算，如視覺識別、語音識別等。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    b1:{
      img: twoSxt,
      name: '攝像頭',
      match: ['d3'],
      icon: icon_m0,
      desc: '攝像頭配備200萬像素攝像頭，兩種安裝方式可選，可作爲前置或後置攝像頭使用，以拍攝前方或後方圖像，記錄及傳送圖像到AI模組。 攝像頭獲取的圖像尺寸爲320 x 240 px及160 x 120 px兩種。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4',
      unitPrice: 200,
      // video:clip_m0,
    },
    b2:{
      img: twoCotroller,
      name: '遊戲手柄',
      match: ['d1', 'd2'],
      icon: icon_m0,
      desc: '遊戲手柄模組內置搖桿與按鈕，可用於自制遊戲或者遠程遙控的項目場景。模組可以分別與AI模組或IoT模組組裝使用。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%B8%B8%E6%88%8F%E6%89%8B%E6%9F%84%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
  },
  s:{
    s0: {
      img: m2iot,
      name: 'IoT 模組',
      match: ['e0','e1', 'e2','e3'],
      icon: icon_m0,
      desc: 'IoT 模組作爲物聯網相關應用的主機板模組，可用於基礎教學及進階項目的開發。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8IoT%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // '光线传感器','温湿度传感器','陀螺仪','麦克风','电容触摸','按键开关'
      arrs:[
        {
          index:0,
          title:'光線傳感器',
          arr:['e0','e3']  
        },
        {
          index:1,
          title:'溫濕度傳感器',
          arr:['e1']  
        },
        {
          index:2,
          title:'陀螺儀',
          arr:['e1']  
        },
        {
          index:3,
          title:'藍牙',
          arr:['e2']  
        },
      ]
      // video:clip_m0,
    },
    s1:{
      img: twoCotroller,
      name: '遊戲手柄',
      match: ['e4'],
      icon: icon_m0,
      desc: '遊戲手柄模組內置搖桿與按鈕，可用於自制遊戲或者遠程遙控的項目場景。模組可以分別與AI模組或IoT模組組裝使用。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%B8%B8%E6%88%8F%E6%89%8B%E6%9F%84%E6%A8%A1%E5%9D%97',
      unitPrice: 200
      // video:clip_m0,
    },
    // s1:{
    //   img: twoSxt,
    //   name: '摄像头',
    //   match: ['d0','d1', 'd2','d3'],
    //   icon: icon_m0,
    //   desc: '摄像头配备200万像素摄像头，两种安装方式可选，可作为前置或后置摄像头使用，以拍摄前方或后方图像，记录及传送图像到AI模块。 摄像头获取的图像尺寸为320 x 240 px及160 x 120 px两种。',
    //   wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4',
    //   unitPrice: 200,
    //   // video:clip_m0,
    // },
    // s2:{
    //   img: twoCotroller,
    //   name: '游戏手柄',
    //   match: ['d0','d1', 'd2','d3'],
    //   icon: icon_m0,
    //   desc: '游戏手柄模块内置摇杆与按钮，可用于自制游戏或者远程遥控的项目场景。模块可以分别与AI模块或IoT模块组装使用。',
    //   wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%B8%B8%E6%88%8F%E6%89%8B%E6%9F%84%E6%A8%A1%E5%9D%97',
    //   unitPrice: 200,
    //   // video:clip_m0,
    // },
  },
  d:{
    d0:{
      img: twoLed,
      name: 'LED 燈屏模組',
      match: ['b0'],
      icon: icon_o1,
      desc: 'LED 燈屏模組是將36粒WS2813 全綵LED燈珠排列形成的電子模組，可以同時控制每粒LED燈的顏色和亮度，來實現基礎圖像繪製和效果顯示。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8LED%E7%81%AF%E5%B1%8F%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    d1:{
      img: twoScreen,
      name: '模組模組',
      match: ['b0','b2'],
      icon: icon_o2,
      desc: '模組模組是一個LCD彩色模組顯示器，模組尺寸爲240 x 240 px (像素)，可顯示JPG, BMP 等格式圖片。集成攝像頭模組插座，供後置拍照使用；模組模組搭載了播放芯片及揚聲器，支持播放WAV格式的音頻文件。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    d2:{
      img: twoMotor,
      name: '電機驅動模組',
      match: ['b0','b2'],
      icon: icon_o3,
      desc: '電機驅動模組內置一個型號爲PCA9685的控制芯片，及2個型號爲TB6612FNG的直流電機驅動芯片。模組可以控制直流電機的速度，並且可以控制伺服馬達的轉動角度。模組設置了外接傳感器接口，可用於接入第三方傳感器獲取數據。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    d3:{
      img: twoExtnsion,
      name: '轉接模組',
      icon: icon_o3,
      match: ['b0','b1'],
      desc: '擴展轉接模組用於外接並控制第三方模組如舵機，以及外接並獲取第三方傳感器數據，如超聲波傳感器、光照傳感器等。模組可以分別與AI模組或IoT模組組裝使用，可以使用USB電源供電或外接電源供電。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    
  },
  e:{
    e0:{
      img: twoLed,
      name: 'LED 燈屏模組',
      match: ['s0'],
      icon: icon_o1,
      desc: 'LED 燈屏模組是將36粒WS2813 全綵LED燈珠排列形成的電子模組，可以同時控制每粒LED燈的顏色和亮度，來實現基礎圖像繪製和效果顯示。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8LED%E7%81%AF%E5%B1%8F%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      a:[0]
      // video:clip_m0,
    },
    e1:{
      img: twoScreen,
      name: '熒幕模組',
      match: ['s0'],
      icon: icon_o2,
      desc: '熒幕模組是一個LCD彩色熒幕顯示器，熒幕尺寸爲240 x 240 px (像素)，可顯示JPG, BMP 等格式圖片。集成攝像頭模組插座，供後置拍照使用；熒幕模組搭載了播放芯片及揚聲器，支持播放WAV格式的音頻文件。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      a:[1,2]
      // video:clip_m0,
    },
    e2:{
      img: twoMotor,
      name: '電機驅動模組',
      match: ['s0'],
      icon: icon_o3,
      desc: '電機驅動模組內置一個型號爲PCA9685的控制芯片，及2個型號爲TB6612FNG的直流電機驅動芯片。模組可以控制直流電機的速度，並且可以控制伺服馬達的轉動角度。模組設置了外接傳感器接口，可用於接入第三方傳感器獲取數據。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      a:[3]
      // video:clip_m0,
    },
    e3:{
      img: twoExtnsion,
      name: '轉接模組',
      icon: icon_o3,
      match: ['s0'],
      desc: '擴展轉接模組用於外接並控制第三方模組如舵機，以及外接並獲取第三方傳感器數據，如超聲波傳感器、光照傳感器等。模組可以分別與AI模組或IoT模組組裝使用，可以使用USB電源供電或外接電源供電。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      a:[0]
      // video:clip_m0,
    },
    e4:{
      img: m2iot,
      name: 'IoT 模块',
      match: ['s1'],
      icon: icon_m0,
      desc: 'IoT 模块作为物联网相关应用的主机板模块，可用于基础教学及进阶项目的开发。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8IoT%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // '光线传感器','温湿度传感器','陀螺仪','麦克风','电容触摸','按键开关'
      // video:clip_m0,
    },

  }
}

export default modules;
