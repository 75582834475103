import React from 'react';
import PropTypes from 'prop-types';
//components
import TitleMain from './titleMain';
import Colors from './colors';
//img
import consumables__img from '../assets/img/consumables_img.jpg';
//css
import '../assets/css/consumables.css';

class Consumables extends React.Component {
  render () {
    return(
      <div className="Consumables">
        <TitleMain name='打印耗材'/>
        <img src={consumables__img} alt="consumables__img" className="Consumables__img" width='100%'/>
        <div className='Consumables__title'>
          <ul className='Consumables__list'>
            <li className='Consumables-list__item'>材料</li>
            <li className='Consumables-list__item'>重量／密度</li>
            <li className='Consumables-list__item'>熔點</li>
            <li className='Consumables-list__item'>溶體流動指數</li>
            <li className='Consumables-list__item'>拉伸屈服強度</li>
            <li className='Consumables-list__item'>斷裂延伸度</li>
            <li className='Consumables-list__item'>彎曲強度</li>
            <li className='Consumables-list__item'>彎曲模量</li>
            <li className='Consumables-list__item'>缺口衝擊強度</li>
            <li className='Consumables-list__item'>支持打印方式</li>
            <li className='Consumables-list__item'>熱變形溫度</li>
            <li className='Consumables-list__item'>打印溫度</li>
            <li className='Consumables-list__item'>底板要求溫度</li>
            <li className='Consumables-list__item'>可選顏色</li>
          </ul>
        </div>
        <div className="Consumables__content">
          <ul className='Consumables__list'>
            <li className='Consumables-list__item'>PLA</li>
            <li className='Consumables-list__item'>1.0KG/卷 1.24g/cm³</li>
            <li className='Consumables-list__item'>180°C</li>
            <li className='Consumables-list__item'>7.8g/19min</li>
            <li className='Consumables-list__item'>62.63MPa</li>
            <li className='Consumables-list__item'>4.43%</li>
            <li className='Consumables-list__item'>65.02Mpa</li>
            <li className='Consumables-list__item'>2504.4MPa</li>
            <li className='Consumables-list__item'>4.28KJ/m²</li>
            <li className='Consumables-list__item'>打印機／打印筆</li>
            <li className='Consumables-list__item'>50°C</li>
            <li className='Consumables-list__item'>190-230°C</li>
            <li className='Consumables-list__item'>50°C</li>
            <li className='Consumables-list__item'>
              <Colors colors={['#000','#fc5b71','#e265a4','#695335','#896daa','#7a7b77','#8a8a88','#A91E1F','#422D28','#EFEFEF','#FB9648','#1E46A7','#AFC53C','#2A110E','#175A2D','#D2956B']}/>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Consumables;
