import React, { Component } from 'react';
import '../assets/css/combination.css';
import '../assets/css/result.css';

//data
import modules from './modules';
import sets from './sets';

//image
import VideoArea from '../assets/img/videoArea.png';
import musicIcon from '../assets/img/icon/theme/music.svg';
import powerIcon from '../assets/img/icon/theme/power.svg';
import lightIcon from '../assets/img/icon/theme/light.svg';
import default_m_icon from '../assets/img/icon/module/defaultM.svg';
import default_i_icon from '../assets/img/icon/module/defaultI.svg';
import default_o_icon from '../assets/img/icon/module/defaultO.svg';
import default_c_icon from '../assets/img/icon/module/defaultC.svg';

//plugin
import scrollToComponent from 'react-scroll-to-component';
import { Button } from '@material-ui/core';


var currentSet = [];
var currentSetIoT = []

class TwoCombination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moduleSet: modules.b,
            moduleSetIoT: modules.s,
            othersSet: modules.d,
            othersSetIoT: modules.e,
            moduleInputId: '',
            moduleOutputId: '',
            title: '',
            titleIoT:'',
            content: '',
            fn: '',
            video: '',
            videoIoT:'',
            placeholder_video: VideoArea,
            placeholder_content: '請從下方各選取一個模組',
            cat: '#',
            cat_width: '0',
            cat_height: '0',
            mIcon: default_m_icon,
            iIcon: default_i_icon,
            oIcon: default_o_icon,
            cIcon: default_c_icon,
            disabled: true,
            AIorIoT: true
        }
        this.moduleClick = this.moduleClick.bind(this);
        this.moduleClickIot = this.moduleClickIot.bind(this);
        this.reset = this.reset.bind(this);
        this.optionClick = this.optionClick.bind(this);
        this.resetIoT = this.resetIoT.bind(this);
        this.optionClickIoT = this.optionClickIoT.bind(this);
    }
    moduleClick(event) {
        if (event.target.nodeName === 'IMG') {
            let getName = event.target.getAttribute('alt');
            //if onclick is inputModule
            if (getName.search(/d/g) !== -1 && (typeof currentSet[0] === 'undefined' ? true : modules.b[currentSet[0]].match.indexOf(getName) !== -1)) {
                //init boxShadow

                if (typeof currentSet[1] !== 'undefined') {
                    document.getElementById(currentSet[1]).style.boxShadow = 'none';
                }
                //set icon
                this.setState({
                    iIcon: modules.d[getName].icon,
                })
                //add boxShadow
                document.getElementById(getName).style.boxShadow = '0 0 5px 2px #E4517B';
                currentSet[1] = getName;
                //click inputmodule first
                let arrA = Object.keys(modules.b);
                let matchOutput = modules.d[getName].match;
                matchOutput.map(x => arrA.splice(arrA.indexOf(x), 1));
                var unmatchOutput = {};
                for (var x = 0; x < arrA.length; x++) {
                    unmatchOutput[arrA[x]] = modules.d[arrA[x]]
                }
                Object.keys(modules.b).forEach(x => document.getElementById(x).style.opacity = '1');
                Object.keys(unmatchOutput).forEach(x => document.getElementById(x).style.opacity = '0.3');
                this.returnResult();
            } else if (getName.search(/b/g) !== -1 && (typeof currentSet[1] === 'undefined' ? true : modules.d[currentSet[1]].match.indexOf(getName) !== -1)) {
                //init boxShadow
                if (typeof currentSet[0] !== 'undefined') {
                    document.getElementById(currentSet[0]).style.boxShadow = 'none';
                }
                //set icon
                this.setState({
                    oIcon: modules.b[getName].icon,
                })
                //add boxShadow
                document.getElementById(getName).style.boxShadow = '0 0 5px 2px #56A6DC';
                currentSet[0] = getName;
                //click outputmodule first
                let arrA = Object.keys(modules.d);
                let matchOutput = modules.b[getName].match;
                matchOutput.map(x => arrA.splice(arrA.indexOf(x), 1));
                let unmatchOutput = {};
                for (let x = 0; x < arrA.length; x++) {
                    unmatchOutput[arrA[x]] = modules.b[arrA[x]]
                }
                Object.keys(modules.d).forEach(x => document.getElementById(x).style.opacity = '1');
                Object.keys(unmatchOutput).forEach(x => document.getElementById(x).style.opacity = '0.3');
                this.returnResult();
            }
        }
    }

    moduleClickIot(event) {
        if (event.target.nodeName === 'IMG' && event.target.getAttribute('style').indexOf('opacity: 0.3') === -1) {
            let getName = event.target.getAttribute('alt');
            //if onclick is inputModule
            if (getName.search(/e/g) !== -1) {
                //init boxShadow

                if (typeof currentSetIoT[1] !== 'undefined') {
                    document.getElementById(currentSetIoT[1] + 'IoT').style.boxShadow = 'none';
                }
                //set icon
                this.setState({
                    iIcon: modules.e[getName].icon,
                })
                //add boxShadow
                document.getElementById(getName + 'IoT').style.boxShadow = '0 0 5px 2px #E4517B';
                currentSetIoT[1] = getName;
                //click inputmodule first   //Object.keys(modules.s)
                let arrA = currentSetIoT[1]!=='e4'?[0, 1, 2, 3]:Object.keys(modules.s);
                let matchOutput =  currentSetIoT[1]!=='e4'?modules.e[getName].a:modules.e[getName].match;
                matchOutput.map(x => arrA.splice(arrA.indexOf(x), 1));
                var unmatchOutput = {};
                for (var x = 0; x < arrA.length; x++) {
                    unmatchOutput[arrA[x]] = currentSetIoT[1]!=='e4'? arrA[x]:modules.e[arrA[x]]
                }
                matchOutput.map(x => document.getElementById(x + 'IoT').style.display = "block");
                if(currentSetIoT[1]!=='e4'){
                    // currentSetIoT[0] = 's0'
                    Object.keys(unmatchOutput).map(x => document.getElementById(x + 'IoT').style.display = "none");
                    Object.keys(modules.s).forEach(x => document.getElementById(x + 'IoT').style.opacity = '1');
                    // modules.s.s0.arrs[currentSetIoT[2] || 0].arr.forEach(x => document.getElementById(x + 'IoT').style.opacity = '1');
                    Object.keys(unmatchOutput).forEach(x => document.getElementById(x + 'IoT').style.opacity = '0.3');
                    document.getElementById('s1IoT').style.opacity = '0.3'
                    currentSetIoT[2] = matchOutput[0]
                }else{
                    // currentSetIoT[0] = 's1'
                    // Object.keys(unmatchOutput).map(x => document.getElementById(x + 'IoT').style.display = "none");
                    Object.keys(modules.s).forEach(x => document.getElementById(x + 'IoT').style.opacity = '1');
                    // modules.s.s0.arrs[currentSetIoT[2] || 0].arr.forEach(x => document.getElementById(x + 'IoT').style.opacity = '1');
                    Object.keys(unmatchOutput).forEach(x => document.getElementById(x + 'IoT').style.opacity = '0.3');
                    currentSetIoT[2] = 0
                }
                
                this.returnResultIot();
            } else if (getName.search(/s/g) !== -1 && (typeof currentSetIoT[1] === 'undefined' ? true : modules.e[currentSetIoT[1]].match.indexOf(getName) !== -1)) {
                //init boxShadow
                if (typeof currentSetIoT[0] !== 'undefined') {
                    document.getElementById(currentSetIoT[0] + 'IoT').style.boxShadow = 'none';
                }
                //set icon
                this.setState({
                    oIcon: modules.s[getName].icon,
                })
                //add boxShadow
                document.getElementById(getName + 'IoT').style.boxShadow = '0 0 5px 2px #56A6DC';
                currentSetIoT[0] = getName;
                //click outputmodule first
                let arrA = Object.keys(modules.e);
                let matchOutput = (modules.s[getName].arrs&&modules.s[getName].arrs[[currentSetIoT[2] || 0]].arr)||modules.s[getName].match;
                matchOutput.map(x => arrA.splice(arrA.indexOf(x), 1));
                let unmatchOutput = {};
                for (let x = 0; x < arrA.length; x++) {
                    unmatchOutput[arrA[x]] = modules.s[arrA[x]]
                }
                Object.keys(modules.e).forEach(x => document.getElementById(x + 'IoT').style.opacity = '1');
                Object.keys(unmatchOutput).forEach(x => document.getElementById(x + 'IoT').style.opacity = '0.3');
                this.returnResultIot();
            }
        }
    }

    checkMoreOption(sets) {
        document.getElementById('titles').innerHTML = `<option value='0'>${sets.title}</option>`;
        Object.keys(sets.more).forEach(function (x, y) {
            let addOptionNode = document.createElement('option');
            let addOptionText = document.createTextNode(sets.more[x].title);
            addOptionNode.appendChild(addOptionText);
            addOptionNode.setAttribute('value', y + 1);
            document.getElementById('titles').appendChild(addOptionNode);
        });

    }
    checkMoreOptionIoT(sets) {
        document.getElementById('titlesIoT').innerHTML = `<option value='0'>${sets.title}</option>`;
        Object.keys(sets.more).forEach(function (x, y) {
            let addOptionNode = document.createElement('option');
            let addOptionText = document.createTextNode(sets.more[x].title);
            addOptionNode.appendChild(addOptionText);
            addOptionNode.setAttribute('value', y + 1);
            document.getElementById('titlesIoT').appendChild(addOptionNode);
        });

    }
    optionClick() {
        let optionValue = document.getElementById('title').value;
        let setName = currentSet.join('');
        this.setState({
            content: optionValue === '0' ? sets[setName].content : sets[setName].more[optionValue].content,
            fn: optionValue === '0' ? sets[setName].fn : sets[setName].more[optionValue].fn,
            placeholder_video: optionValue === '0' ? sets[setName].img : sets[setName].more[optionValue].img,
            cat: optionValue === '0' ? this.cat(sets[setName].cat) : this.cat(sets[setName].more[optionValue].cat),
        });
    }
    optionClickIoT() {
        let optionValue = document.getElementById('titleIoT').value;
        let setName = currentSet.join('');
        this.setState({
            content: optionValue === '0' ? sets[setName].content : sets[setName].more[optionValue].content,
            fn: optionValue === '0' ? sets[setName].fn : sets[setName].more[optionValue].fn,
            placeholder_video: optionValue === '0' ? sets[setName].img : sets[setName].more[optionValue].img,
            cat: optionValue === '0' ? this.cat(sets[setName].cat) : this.cat(sets[setName].more[optionValue].cat),
        });
    }
    cat(cat) {
        let theme;
        switch (cat) {
            case 'light':
                theme = lightIcon;
                break;
            case 'music':
                theme = musicIcon;
                break;
            case 'power':
                theme = powerIcon;
                break;
            default:
                theme = '';
        }
        return theme;
    }
    returnResult() {
        if (typeof currentSet[0] !== 'undefined' && typeof currentSet[1] !== 'undefined') {
            //add reset Button
            document.getElementById('resets').style.display = 'block';
            let setName = currentSet.join('');
            //check option existed
            if (typeof sets[setName].more !== 'undefined') {
                this.checkMoreOption(sets[setName]);
                document.querySelectorAll('#content h2')[0].classList.add('option');
                this.setState({
                    disabled: false,
                });
            } else {
                document.getElementById('titles').innerHTML = `<option>${sets[setName].title}</option>`;
                document.querySelectorAll('#content h2')[0].classList.remove('option');
                this.setState({
                    disabled: true,
                });
            }
            this.setState({
                title: sets[setName].title,
                content: sets[setName].content,
                fn: sets[setName].fn,
                placeholder_content: '',
                placeholder_video: sets[setName].img,
                video: sets[setName].video,
                cat: this.cat(sets[setName].cat),
                cat_width: '100%',
                cat_height: '100%',
                mIcon: modules.b.b0.icon,
                // iIcon:modules.i[currentSet[0]].icon,
                // oIcon:modules.o[currentSet[1]].icon,
            });
            scrollToComponent(this.result, { offset: 0, align: 'top', duration: 300, ease: 'inCirc' });
            // document.getElementById('guide').style.display='none';
        }
    }

    returnResultIot() {
        currentSetIoT[2] = currentSetIoT[2] || 0
        if (typeof currentSetIoT[0] !== 'undefined' && typeof currentSetIoT[1] !== 'undefined' && typeof currentSetIoT[2] !== 'undefined') {
            //add reset Button
            document.getElementById('resetsIoT').style.display = 'block';
            currentSetIoT[2] = currentSetIoT[0] === "s1"?0:currentSetIoT[2]
            var setName = currentSetIoT.join('');
            console.log(sets,setName,currentSetIoT)
            //check option existed
            if (typeof sets[setName].more !== 'undefined') {
                this.checkMoreOptionIoT(sets[setName]);
                document.querySelectorAll('#contentIoT h2')[0].classList.add('option');
                this.setState({
                    disabled: false,
                });
            } else {
                document.getElementById('titlesIoT').innerHTML = `<option>${sets[setName].title}</option>`;
                document.querySelectorAll('#contentIoT h2')[0].classList.remove('option');
                this.setState({
                    disabled: true,
                });
            }
            this.setState({
                titleIoT: sets[setName].title,
                contentIoT: sets[setName].content,
                fnIoT: sets[setName].fn,
                placeholder_content: '',
                placeholder_video: sets[setName].img,
                videoIoT:sets[setName].video,
                cat: this.cat(sets[setName].cat),
                cat_width: '100%',
                cat_height: '100%',
                mIcon: modules.b.b0.icon,
                // iIcon:modules.i[currentSet[0]].icon,
                // oIcon:modules.o[currentSet[1]].icon,
            });
            scrollToComponent(this.resultIoT, { offset: 0, align: 'top', duration: 300, ease: 'inCirc' });
            // document.getElementById('guide').style.display='none';
        }
    }
    reset() {
        //init boxShadow
        if (typeof currentSet[0] !== 'undefined' && typeof currentSet[1] !== 'undefined') {
            document.getElementById(currentSet[0]).style.boxShadow = 'none';
            document.getElementById(currentSet[1]).style.boxShadow = 'none';
            document.getElementById('titles').innerHTML = '';
            currentSet = [];
            let modules_all = Object.assign({}, modules.b, modules.d);
            Object.keys(modules_all).forEach(x => document.getElementById(x).style.opacity = '1');
            this.setState({
                moduleSet: modules.b,
                othersSet: modules.d,
                moduleInputId: '',
                moduleOutputId: '',
                title: '',
                content: '',
                fn: '',
                video: '',
                placeholder_video: VideoArea,
                placeholder_content: '請從下方各選取一個模組',
                cat: '',
                cat_width: '0',
                mIcon: default_m_icon,
                iIcon: default_i_icon,
                oIcon: default_o_icon,
                cIcon: default_c_icon,
                disabled: true,
            });
            scrollToComponent(this.selection, { offset: 0, align: 'top', duration: 300, ease: 'inCirc' });
            //remove reset Button
            document.getElementById('resets').style.display = 'none';
            //remove option toggle triangle
            document.querySelectorAll('#content h2')[0].classList.remove('option');
        }
    }
    resetIoT() {
        //init boxShadow
        if (typeof currentSetIoT[0] !== 'undefined' && typeof currentSetIoT[1] !== 'undefined') {
            document.getElementById(currentSetIoT[0] + 'IoT').style.boxShadow = 'none';
            document.getElementById(currentSetIoT[1] + 'IoT').style.boxShadow = 'none';
            document.getElementById('titlesIoT').innerHTML = '';
            [0, 1, 2, 3].map(x => document.getElementById(x + 'IoT').style.display = "block")
            currentSetIoT = [];
            let modules_all = Object.assign({}, modules.s, modules.e);
            Object.keys(modules_all).forEach(x => document.getElementById(x + 'IoT').style.opacity = '1');

            this.setState({
                moduleSetIoT: modules.s,
                othersSetIoT: modules.e,
                titleIoT: '',
                contentIoT: '',
                fnIoT: '',
                videoIoT: '',
                placeholder_video: VideoArea,
                placeholder_content: '請從下方各選取一個模組',
                cat: '',
                cat_width: '0',
                mIcon: default_m_icon,
                iIcon: default_i_icon,
                oIcon: default_o_icon,
                cIcon: default_c_icon,
                disabled: true,
            });
            scrollToComponent(this.selectionIoT, { offset: 0, align: 'top', duration: 300, ease: 'inCirc' });
            //remove reset Button
            document.getElementById('resetsIoT').style.display = 'none';
            //remove option toggle triangle
            document.querySelectorAll('#contentIoT h2')[0].classList.remove('option');
        }
    }
    getCurrentSetIoT3 = (i) => {
        if(currentSetIoT[0] !== "s0") return
        currentSetIoT[2] = i
        // this.returnResultIot()
        this.setState({
            moduleSetIoT: modules.s,
            othersSetIoT: modules.e,
        })
        let arrA = Object.keys(modules.e);
        console.log(modules.s[currentSetIoT[0]])
        let matchOutput = (currentSetIoT[0] === "s0" && modules.s[currentSetIoT[0]] && modules.s[currentSetIoT[0]].arrs[[currentSetIoT[2] || 0]].arr)|| 0;
        console.log(matchOutput)
        matchOutput.map(x => arrA.splice(arrA.indexOf(x), 1));
        let unmatchOutput = {};
        for (let x = 0; x < arrA.length; x++) {
            unmatchOutput[arrA[x]] = modules.s[arrA[x]]
        }
        Object.keys(modules.e).forEach(x => document.getElementById(x + 'IoT').style.opacity = '1');
        Object.keys(unmatchOutput).forEach(x => document.getElementById(x + 'IoT').style.opacity = '0.3');
        this.returnResultIot();
    }
    AIorIoT=(type)=>{
        if(type){
            document.getElementById('titlesIoT').innerHTML = '';
            document.getElementById('resetsIoT').style.display = 'none';
            this.setState({
                AIorIoT:type,
                moduleSetIoT: modules.s,
                othersSetIoT: modules.e,
                titleIoT: '',
                contentIoT: '',
                fnIoT: '',
                videoIoT: '',
                placeholder_video: VideoArea,
                placeholder_content: '請從下方各選取一個模組',
                cat: '',
                cat_width: '0',
                mIcon: default_m_icon,
                iIcon: default_i_icon,
                oIcon: default_o_icon,
                cIcon: default_c_icon,
                disabled: true,
            });
        }else{
            document.getElementById('titles').innerHTML = '';
            document.getElementById('resets').style.display = 'none'; 
            this.setState({
                AIorIoT:type,
                moduleSet: modules.b,
                othersSet: modules.d,
                moduleInputId: '',
                moduleOutputId: '',
                title: '',
                content: '',
                fn: '',
                video: '',
                placeholder_video: VideoArea,
                placeholder_content: '請從下方各選取一個模組',
                cat: '',
                cat_width: '0',
                mIcon: default_m_icon,
                iIcon: default_i_icon,
                oIcon: default_o_icon,
                cIcon: default_c_icon,
                disabled: true,
            });
        }
    }
    render() {
        return (
            <div className='wrapper selection'>
                <div style={{display:'flex',marginTop:30}}>
                    <h1 className='title' style={{ marginTop: "0",cursor:'pointer' }} onClick={() => this.AIorIoT(true)}>AI 模組</h1>
                    <h1 className='title' style={{ marginTop: "0",cursor:'pointer' }} onClick={() => this.AIorIoT(false)}>IoT 模組</h1>
                </div>
                {/*<p id='guide' style={{position:'absolute',left:'50%',top:'200px',transform:'translateX(-50%)',color:'#E4517B'}} className='font'>请选择I/O模块</p> */}
                {this.state.AIorIoT ? <div>
                    <div id='result' className='font' ref={(section) => { this.result = section; }}>
                        <header>結果展示區</header>
                        <div id='video'>
                            <iframe src={this.state.video} width="100%" height='100%' frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen='true'></iframe>
                            <span><img src={this.state.placeholder_video} alt='videoArea' width='100%' height='100%' /></span>
                        </div>
                        <div className="theme"><img src={this.state.cat} width={this.state.cat_width} height={this.state.cat_height} alt='' /></div>
                        <div className="moduleIcon">
                            <div className="mIcon icon"><img src={this.state.mIcon} width='100%' height="100%" alt='mIcon' /></div>
                            <div className="iIcon icon"><img src={this.state.iIcon} width='100%' height="100%" alt='iIcon' /></div>
                            <div className="oIcon icon"><img src={this.state.oIcon} width='100%' height="100%" alt='oIcon' /></div>
                            <div className="cIcon icon"><img src={this.state.cIcon} width='100%' height="100%" alt='cIcon' /></div>
                        </div>
                        <div id='content'>
                            <h2 className=''>
                                <select id='titles' disabled={this.state.disabled} style={this.state.disabled ? { backgroundColor: 'transparent' } : { backgroundColor: '#fff' }} onChange={this.optionClick}>
                                </select>
                            </h2>
                            <p className='p1'>{this.state.content}</p>
                            <p className='p2'>{this.state.fn}</p>
                            <span id='placeholder'>{this.state.placeholder_content}</span>
                        </div>
                    </div>
                    <Button raised color='primary' id='resets' style={{ display: 'none' }} onClick={this.reset}>
                        重置
                    </Button>
                    <div id='selection' className='selectionContainer' ref={(section) => { this.selection = section; }}>
                        <div className='inputModuleBlock block font'>
                            <header>輸入模組</header>
                            <ul id='twomoduleInput' onClick={this.moduleClick}>
                                {Object.keys(this.state.moduleSet).map((x) => (<li key={x} className={x} ><img id={x} alt={x} src={this.state.moduleSet[x].img} style={{ width: '100%', padding: '0 10px 10px 10px', background: '#fff' }} /><span>{this.state.moduleSet[x].name}</span></li>))}
                            </ul>
                        </div>
                        <div className='outputModuleBlock block font'>
                            <header>輸出模組</header>
                            <ul id='twomoduleOutput' onClick={this.moduleClick}>
                                {Object.keys(this.state.othersSet).map((x) => (<li key={x} className={x}><img id={x} alt={x} src={this.state.othersSet[x].img} style={{ width: '100%', padding: '0 10px 10px 10px', background: '#fff' }} /><span>{this.state.othersSet[x].name}</span></li>))}
                            </ul>
                        </div>
                    </div>
                </div> :
                    <div>
                        <div id='resultIoT' className='font' ref={(section) => { this.resultIoT = section; }}>
                            <header>結果展示區</header>
                            <div id='videoIoT'>
                                <iframe src={this.state.videoIoT} width="100%" height='100%' frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen='true'></iframe>
                                <span><img src={this.state.placeholder_video} alt='videoArea' width='100%' height='100%' /></span>
                            </div>
                            <div className="theme"><img src={this.state.cat} width={this.state.cat_width} height={this.state.cat_height} alt='' /></div>
                            <div className="moduleIcon">
                                <div className="mIcon icon"><img src={this.state.mIcon} width='100%' height="100%" alt='mIcon' /></div>
                                <div className="iIcon icon"><img src={this.state.iIcon} width='100%' height="100%" alt='iIcon' /></div>
                                <div className="oIcon icon"><img src={this.state.oIcon} width='100%' height="100%" alt='oIcon' /></div>
                                <div className="cIcon icon"><img src={this.state.cIcon} width='100%' height="100%" alt='cIcon' /></div>
                            </div>
                            <div id='contentIoT'>
                                <h2 className=''>
                                    <select id='titlesIoT' disabled={this.state.disabled} style={this.state.disabled ? { backgroundColor: 'transparent' } : { backgroundColor: '#fff' }} onChange={this.optionClickIoT}>
                                    </select>
                                </h2>
                                <p className='p1'>{this.state.contentIoT}</p>
                                <p className='p2'>{this.state.fnIoT}</p>
                                <span id='placeholderIoT'>{this.state.placeholder_content}</span>
                            </div>
                        </div>
                        <Button raised color='primary' id='resetsIoT' style={{ display: 'none' }} onClick={this.resetIoT}>
                            重置
                        </Button>
                        <div id='selectionIoT' className='selectionContainer' ref={(section) => { this.selectionIoT = section; }}>
                            <div className='inputModuleBlock block font'>
                                <header>輸入模組</header>
                                <ul id='twomoduleInput' onClick={this.moduleClickIot}>
                                    {/* && currentSetIoT[0] == 's0' */}
                                    {Object.keys(this.state.moduleSetIoT).map((x) => (<li key={x} className={x} ><img id={x + 'IoT'} alt={x} src={this.state.moduleSetIoT[x].img} style={{ width: '100%', padding: '0 10px 10px 10px', background: '#fff' }} /><span>{this.state.moduleSetIoT[x].name}</span>
                                        {(this.state.moduleSetIoT[x].arrs && this.state.moduleSetIoT[x].arrs.length > 0) && <ul style={{ width: '100%', position: 'absolute', padding: 0, background: '#9cc8e7' }}>
                                            {this.state.moduleSetIoT[x].arrs.map((s, i) => (<li id={s.index + 'IoT'} key={s.title} className={currentSetIoT[2] == s.index ? 'active' : ''} onClick={() => this.getCurrentSetIoT3(s.index)} style={{ cursor: 'pointer',opacity:'0.6' }}>{s.title}</li>))}
                                        </ul>}
                                    </li>))}
                                </ul>
                            </div>
                            <div className='outputModuleBlock block font'>
                                <header>輸出模組</header>
                                <ul id='twomoduleOutput' onClick={this.moduleClickIot}>
                                    {Object.keys(this.state.othersSetIoT).map((x) => (<li key={x} className={x}><img id={x + 'IoT'} alt={x} src={this.state.othersSetIoT[x].img} style={{ width: '100%', padding: '0 10px 10px 10px', background: '#fff' }} /><span>{this.state.othersSetIoT[x].name}</span></li>))}
                                </ul>
                            </div>
                        </div></div>}
            </div>
        )
    }
}

export default TwoCombination;