import {observable} from 'mobx'

class InquiryStore {
  @observable light = 0;
  @observable lightExtension = false;
  @observable IoT = 0;
  @observable IoTExtension = false;
  @observable mechanism = 0;
  @observable mechanismExtension = false;
  @observable power = 0;
  @observable powerExtension = false;
  @observable music = 0;
  @observable musicExtension = false;
  @observable HCI = 0;
  @observable HCIExtension = false;
  @observable fullKit = 0;

  getList(){
    let list = {
      '燈光 Basic Kit':this.light,
      '擴展材料包：百變臺燈製作':this.lightExtension,
      '物聯網Basic Kit':this.IoT,
      '擴展材料包：迷你氣象站製作':this.IoTExtension,
      '機械 Basic Kit':this.mechanism,
      '擴展材料包：激光切割機械臂製作':this.mechanismExtension,
      '動力 Basic Kit':this.power,
      '擴展材料包：激光切割風火輪製作':this.powerExtension,
      '音樂Basic Kit':this.music,
      '擴展材料包：尤克里里製作':this.musicExtension,
      '人機交互 Basic Kit':this.HCI,
      '擴展材料包：遊戲手柄製作':this.HCIExtension,
      '全套套件':this.fullKit,
    }
    return list
  }
}

var inquiryStore =window.inquiryStore= new InquiryStore;

export default inquiryStore;
