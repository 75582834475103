import React from 'react'
import PropTypes from 'prop-types'
import TitleMain from './titleMain';
import '../assets/css/contactInfo.css';
import {observer} from 'mobx-react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import config from '../config'

//store


class TakeOrder extends React.Component {
  constructor(props){
    super(props);
    this.state={
      orderState: false,
    }
  }
  submitOrder(){
    this.props.store.isLoading = true;
    var self = this;
    axios.post(config.server+'/api/order',{
      order:this.props.store.order,
      name:document.getElementById('ContactInfo__input--collegeName').value,
      address:document.getElementById('ContactInfo__input--collegeAddress').value,
      amount:this.props.store.amount,
    })
    .then(function(response){
      if(response.data.status == 'OK'){
        self.props.store.addOrderNumber(response.data.orderNumber);
        self.props.store.addOrderName(response.data.collegeName);
        self.props.store.isLoading = false;
        self.setState({
          orderState: true,
        })
      }
    })
    .catch(function(error){
      console.log(error);
    })
  }
  render () {
    if(this.state.orderState){
      return <Redirect to='/orderInfo/succeed'/>
    }
    return(
      <div>
        <TitleMain name='訂單信息'/>
        <div className='TakeOrder'>
          <div className='OrderInfo'>
            <h2 style={{padding:25}}>清單</h2>
            {[...this.props.store.order].map((x,y)=><div key={x+y}>{x[0]+'   x   '+x[1]}</div>)}
            <h5 style={{borderTop:'1px solid #000',padding:'25px'}}><span style={{fontSize:20}}>{this.props.store.amount} </span> HKD</h5>
          </div>
          <div className='ContactInfo'>
            <div className='ContactInfo__collegeName'>
              <span className='ContactInfo__title'>學校名稱</span>
              <input type='text'  className='ContactInfo__input' id='ContactInfo__input--collegeName' maxLength='100'/>
              <p className='ContactInfo__prompt'></p>
            </div>
            <div className='ContactInfo__collegeAddress'>
              <span className='ContactInfo__title'>學校地址</span>
              <input type='text' className='ContactInfo__input' id='ContactInfo__input--collegeAddress' maxLength='100'/>
            </div>
            <button className='ContactInfo__button' onClick={this.submitOrder.bind(this)}>下單</button>
          </div>
        </div>
      </div>
    )
  }
}

export default TakeOrder;
