import {observable, computed} from 'mobx';

class OrderStore{
  @observable order = new Map();
  @observable info = [];
  @observable login = false;
  @observable isLoading = '';
  //isHomepage
  @observable isHomepage = false;
  //Username
  @observable username = '';
  //alias
  @observable alias = 'User';
  //Register
  @observable registered = false;
  //RedirectToRegister
  @observable directToRegister = false;
  //googleId
  @observable googleId = '';
  //cocomod
  @observable cocomodFocusModuleName = '';
  @observable cocomodFocusModuleCat = '';
  @computed get amount(){
      if([...this.order].length > 0){
       return [...store.order].map(x=> x[1][1]*x[1][0]).reduce((total,num)=> total+num);
      }
  }
  //OrderList component state
  @computed get isEmpty(){
    if([...this.order].length == 0){
      let str = '清單為空';
      return str;
    }
  }
  addItem(name,quantity,unitPrice){
    let content = [unitPrice, quantity];
    this.order.set(name,content);
  }
  addOrderName(name){
    this.info.push(name);
  }
  addOrderNumber(number){
    this.info.push(number);
  }
  editItem(name,quantity,unitPrice){
    let content = [unitPrice, quantity];
    this.order.set(name,content);
  }
}

var store = new OrderStore;

export default store;
