import mechanical1 from '../assets/img/一代/机械主题/3D打印机械爪.png'
import mechanical2 from '../assets/img/一代/机械主题/3D打印双足机器人.png'
import mechanical3 from '../assets/img/一代/机械主题/激光切割机械臂.png'
import mechanical4 from '../assets/img/一代/机械主题/激光切割机械爪.png'
import mechanical5 from '../assets/img/一代/机械主题/激光切割双足机器人.png'
// IOT 
import IoT1 from '../assets/img/一代/物联网主题/3D打印气象站.png';
import IoT2 from '../assets/img/一代/物联网主题/3D打印植物监测器.png';
import IoT3 from '../assets/img/一代/物联网主题/激光切割NB-IoT气象站（带屏幕）.png';
import IoT4 from '../assets/img/一代/物联网主题/激光切割NB-IoT气象站.png';
import IoT5 from '../assets/img/一代/物联网主题/激光切割简易版气象站.png';
import IoT6 from '../assets/img/一代/物联网主题/激光切割气象站.png';
import IoT7 from '../assets/img/一代/物联网主题/蓝牙防丢器.png';

// 动力
import power1 from '../assets/img/一代/动力主题/3D打印动力小车.png';
import power2 from '../assets/img/一代/动力主题/单电机双足机器人.png';
import power3 from '../assets/img/一代/动力主题/激光切割动力小车.png';
import power4 from '../assets/img/一代/动力主题/激光切割风火轮.png';
import power5 from '../assets/img/一代/动力主题/激光切割爬行机器人.png';
import power6 from '../assets/img/一代/动力主题/万花筒绘圆装置.png';
import power7 from '../assets/img/一代/动力主题/星球转动仪.png';
import power8 from '../assets/img/一代/动力主题/纸质卡通小车.png';
// 人机交互
import interaction1 from '../assets/img/一代/人机交互主题/互动手套.png';
import interaction2 from '../assets/img/一代/人机交互主题/激光切割手柄.png';
import interaction3 from '../assets/img/一代/人机交互主题/纸质游戏手柄.png';

// 音乐
import music1 from '../assets/img/一代/音乐主题/3D打印灯光电子琴.png';
import music2 from '../assets/img/一代/音乐主题/3D打印电子琴.png';
import music3 from '../assets/img/一代/音乐主题/激光切割电子鼓.png';
import music4 from '../assets/img/一代/音乐主题/激光切割尤克里里.png';
import music5 from '../assets/img/一代/音乐主题/纸模型电子琴.png';
// 灯光
import led1 from '../assets/img/一代/灯光主题/2×2灯屏.png';
import led2 from '../assets/img/一代/灯光主题/3D打印月球灯.png';
import led3 from '../assets/img/一代/灯光主题/激光切割百变灯台.png';
import led4 from '../assets/img/一代/灯光主题/激光切割触摸台灯.png';

// 木质
import wooden1 from '../assets/img/二代/木质结构主题/宝丽来相机.png';
import wooden2 from '../assets/img/二代/木质结构主题/垃圾分类装置侧面.png';
import wooden3 from '../assets/img/二代/木质结构主题/麦克纳姆轮机械臂车.png';
import wooden4 from '../assets/img/二代/木质结构主题/麦克纳姆轮机械车.png';
import wooden5 from '../assets/img/二代/木质结构主题/人脸识别双足机器人.png';
import wooden6 from '../assets/img/二代/木质结构主题/瓦力机器人.png';
import wooden7 from '../assets/img/二代/木质结构主题/音乐播放器.png';
import wooden8 from '../assets/img/二代/木质结构主题/云台追踪.png';
import wooden9 from '../assets/img/二代/木质结构主题/招财猫.png';
import wooden10 from '../assets/img/二代/木质结构主题/智能机械臂.png';
import wooden11 from '../assets/img/二代/木质结构主题/智能闸门.png';
import wooden12 from '../assets/img/二代/木质结构主题/自动驾驶遥控车.png';
// 金属
import metal1 from '../assets/img/二代/金属结构主题/双足机器人1-PNG.png';
import metal2 from '../assets/img/二代/金属结构主题/四足机器人-02-PNG.png';
import metal3 from '../assets/img/二代/金属结构主题/四足机器人加强版-PNG.png';
import metal4 from '../assets/img/二代/金属结构主题/物体追踪小车.png';
import metal5 from '../assets/img/二代/金属结构主题/新垃圾分类-PNG.png';
import metal6 from '../assets/img/二代/金属结构主题/新瓦力机器人-PNG.png';
import metal7 from '../assets/img/二代/金属结构主题/新智能机械臂-PNG.png';
import metal8 from '../assets/img/二代/金属结构主题/新智能闸门-PNG.png';
import metal9 from '../assets/img/二代/金属结构主题/智能宠物.png';

// 模块插画图片
import LED from '../assets/img/一代模块（插画）/LED灯屏模块.png';
import NBIoT from '../assets/img/一代模块（插画）/NBIoT通信模块.png';
import OLED from '../assets/img/一代模块（插画）/OLED灯屏模块.png';
import WiFi from '../assets/img/一代模块（插画）/Wi-Fi通信模块.png';
import TOUCH from '../assets/img/一代模块（插画）/触摸感应模块.png';
import MOTOR from '../assets/img/一代模块（插画）/电机驱动模块.png';
import GEAR from '../assets/img/一代模块（插画）/舵机转接模块.png';
import HUANJING from '../assets/img/一代模块（插画）/环境感应模块.png';
import TEACHING from '../assets/img/一代模块（插画）/教学模块.png';
import BLUE from '../assets/img/一代模块（插画）/蓝牙通信模块.png';
import TRANSFER1 from '../assets/img/一代模块（插画）/平行转接模块.png';
import COLOR from '../assets/img/一代模块（插画）/颜色分析模块.png';
import MUSIC from '../assets/img/一代模块（插画）/音乐模块.png';
import MOVEMENT from '../assets/img/一代模块（插画）/运动感应模块.png';
import TRANSFER2 from '../assets/img/一代模块（插画）/正反转接模块.png';
import MAIN from '../assets/img/一代模块（插画）/主控模块.png';
import TRANSFERA1 from '../assets/img/一代模块（插画）/转接模块A1.png';
import TRANSFERA2 from '../assets/img/一代模块（插画）/转接模块A2.png';
import TRANSFERB1 from '../assets/img/一代模块（插画）/转接模块B1.png';
import TRANSFERB2 from '../assets/img/一代模块（插画）/转接模块B2.png';
import DIANYUAN from '../assets/img/一代模块（插画）/电源管理模块.png';
import AI from '../assets/img/二代模块（插画斜角）/AI模块.png';
import IoT from '../assets/img/二代模块（插画斜角）/IoT模块.png';
import LED2 from '../assets/img/二代模块（插画斜角）/LED灯屏模块.png';
import MOTORTWO from '../assets/img/二代模块（插画斜角）/电机驱动模块.png';
import HANDLE from '../assets/img/二代模块（插画斜角）/控制手柄模块.png';
import EXTENSION from '../assets/img/二代模块（插画斜角）/扩展转接模块.png';
import SCREEN from '../assets/img/二代模块（插画斜角）/屏幕模块.png';
import CAMERA from '../assets/img/二代模块（插画斜角）/摄像头模块.png';


const demo = {
    one:[
        [// 机械
        {id:0,name:"3D打印機械爪",img:mechanical1,
        video:"https://cocorobo.cn/downloads/%E8%A7%86%E9%A2%91/3D%E6%89%93%E5%8D%B0%E6%9C%BA%E6%A2%B0%E7%88%AA.mp4",
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"舵機轉接模組",img:GEAR,url:"https://help.cocorobo.hk/#/cocomod/servo"},
            {name:"教學模組",img:TEACHING,url:"https://help.cocorobo.hk/#/cocomod/sensor-101"}
        ],
        module:"主控模組,舵機轉接模組,教學模組",
        content:"通過控制一個舵機,驅動三個機械爪臂張合,進行抓取物體"},
        {id:1,name:"3D打印雙足機器人",img:mechanical2,
        video:"https://cocorobo.cn/downloads/%E8%A7%86%E9%A2%91/3D%E6%89%93%E5%8D%B0%E5%8F%8C%E8%B6%B3%E6%9C%BA%E5%99%A8%E4%BA%BA.mp4",
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"舵機轉接模組",img:GEAR,url:"https://help.cocorobo.hk/#/cocomod/servo"},
            {name:"LED燈屏模組",img:LED,url:"https://help.cocorobo.hk/#/cocomod/led-matrix"},
            {name:"電源管理模組",img:DIANYUAN}
        ],
        module:"主控模組,舵機轉接模組,電源管理模組,LED燈屏模組",
        content:"通過控制四個舵機,驅動雙足機器人運動"},
        {id:2,name:"激光切割機械臂",img:mechanical3,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"舵機轉接模組",img:GEAR,url:"https://help.cocorobo.hk/#/cocomod/servo"},
            {name:"藍牙通信模組",img:BLUE,url:"https://help.cocorobo.hk/#/cocomod/bluetooth"}
        ],
        module:"主控模組,舵機轉接模組,藍牙通信模組",
        content:"通過控制四個舵機,驅動機械臂關節運動,可進行物體抓取功能"},
        {id:3,name:"激光切割機械爪",img:mechanical4,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"舵機轉接模組",img:GEAR,url:"https://help.cocorobo.hk/#/cocomod/servo"},
            {name:"教學模組",img:TEACHING,url:"https://help.cocorobo.hk/#/cocomod/sensor-101"}
        ],
        module:"主控模組,舵機轉接模組,教學模組",
        content:"通過控制一個舵機,驅動機械爪張合,進行抓取物體"},
        {id:4,name:"激光切割雙足機器人",img:mechanical5,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"舵機轉接模組",img:GEAR,url:"https://help.cocorobo.hk/#/cocomod/servo"},
            {name:"LED燈屏模組",img:LED,url:"https://help.cocorobo.hk/#/cocomod/led-matrix"},
            {name:"電源管理模組",img:DIANYUAN}
        ],
        module:"主控模組,舵機轉接模組,電源管理模組,LED燈屏模組",
        content:"通過控制四個舵機,驅動雙足機器人運動"}
        ],
        [ // 物联网
        {id:5,name:"3D打印氣象站",img:IoT1,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"環境感應模組",img:HUANJING,url:"https://help.cocorobo.hk/#/cocomod/environment"},
            {name:"熒幕模組",img:OLED,url:"https://help.cocorobo.hk/#/cocomod/screen"},
            {name:"轉接模組A2",img:TRANSFERA2,url:"https://help.cocorobo.hk/#/cocomod/hub"}
        ],
        module:"主控模組,環境感應模組,熒幕模組,轉接模組A2",
        content:"通過環境模組可檢測環境的溫度,溼度,聲音,光照強度,並在熒幕模組顯示"},
        {id:6,name:"3D打印植物監測器",img:IoT2,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"環境感應模組",img:HUANJING,url:"https://help.cocorobo.hk/#/cocomod/environment"},
            {name:"熒幕模組",img:OLED,url:"https://help.cocorobo.hk/#/cocomod/screen"},
            {name:"轉接模組A2",img:TRANSFERA2,url:"https://help.cocorobo.hk/#/cocomod/hub"}
        ],
        module:"主控模組,環境感應模組,熒幕模組,轉接模組A2",
        content:"通過土壤傳感器接觸土壤,可檢測土壤的溫度及溼度,並在熒幕模組顯示"},
        {id:7,name:"激光切割NB-IoT氣象站（帶熒幕）",img:IoT3,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"環境感應模組",img:HUANJING,url:"https://help.cocorobo.hk/#/cocomod/environment"},
            {name:"NBIOT通信模組",img:NBIoT,url:"https://help.cocorobo.hk/#/cocomod/nbiot"},
            {name:"正反轉接模組",img:TRANSFER2,url:"https://help.cocorobo.hk/#/cocomod/reversed-adapter"},
            {name:"熒幕模組",img:OLED,url:"https://help.cocorobo.hk/#/cocomod/screen"}
        ],
        module:"主控模組,環境感應模組,NBIOT通信模組,正反轉接模組,熒幕模組",
        content:"獲取環境四組數據,並顯示在熒幕上,通過NBIOT模組上傳數據至對應的雲端事件中,在上傳前,要先更改雲端事件的 API Key"},
        {id:8,name:"激光切割NB-IoT氣象站",img:IoT4,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/93715dc9-e735-f642-3515-647d5b164eba1//%E7%BB%84%E8%A3%85NB-%20IOT%E6%B0%94%E8%B1%A1%E7%AB%99.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"環境感應模組",img:HUANJING,url:"https://help.cocorobo.hk/#/cocomod/environment"},
            {name:"NBIOT通信模組",img:NBIoT,url:"https://help.cocorobo.hk/#/cocomod/nbiot"}
        ],
        module:"主控模組,環境感應模組,NBIOT通信模組",
        content:"獲取環境四組數據,通過NBIOT模組上傳資料至對應的雲端事件中,上傳前要先更改雲端事件的 API Key"},
        {id:9,name:"激光切割簡易版氣象站",img:IoT5,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/102925dd-412e-d18b-3503-bfab95e8ef341//%E7%BB%84%E8%A3%85%E4%BA%91%E7%AB%AF%E6%A3%80%E6%B5%8B%E6%B0%94%E8%B1%A1%E7%AB%99.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"環境感應模組",img:HUANJING,url:"https://help.cocorobo.hk/#/cocomod/environment"},
            {name:"Wi-Fi通信模組",img:WiFi,url:"https://help.cocorobo.hk/#/cocomod/wifi"}
        ],
        module:"主控模組,環境感應模組,Wi-Fi通信模組",
        content:"通過環境模組可檢測環境的溫度,溼度,聲音,光照強度,通過Wi-Fi通信模組上傳資料至對應的雲端事件中,上傳前要先更改雲端事件的 API Key"},
        {id:10,name:"激光切割氣象站",img:IoT6,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/2260ca58-6bf6-cf92-4411-b0081ce1d790/%E6%BF%80%E5%85%89%E5%88%87%E5%89%B2%E6%B0%94%E8%B1%A1%E7%AB%99_%E6%BF%80%E5%85%89%E5%88%87%E5%89%B2%E6%B0%94%E8%B1%A1%E7%AB%99%E7%9A%84%E5%88%B6%E4%BD%9C_%E6%93%8D%E4%BD%9C19-1_v0.1.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"環境感應模組",img:HUANJING,url:"https://help.cocorobo.hk/#/cocomod/environment"},
            {name:"熒幕模組",img:OLED,url:"https://help.cocorobo.hk/#/cocomod/screen"},
            {name:"轉接模組A2",img:TRANSFERA2,url:"https://help.cocorobo.hk/#/cocomod/hub"}
        ],
        module:"主控模組,環境感應模組,熒幕模組,轉接模組A2",
        content:"通過環境模組可檢測環境的溫度,溼度,聲音,光照強度,並在熒幕模組顯示"},
        {id:11,name:"藍牙防丟器",img:IoT7,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"教學模組",img:TEACHING,url:"https://help.cocorobo.hk/#/cocomod/sensor-101"},
            {name:"電源管理模組",img:DIANYUAN},
            {name:"藍牙通信模組",img:BLUE,url:"https://help.cocorobo.hk/#/cocomod/bluetooth"}
        ],
        module:"主控模組,教學模組,電源管理模組,藍牙通信模組",
        content:"通過藍牙連接,當超過一定距離,蜂鳴器則發出警報"}
        ],
        [// 动力
        {id:12,name:"3D打印動力小車",img:power1,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"電機驅動模組",img:MOTOR,url:"https://help.cocorobo.hk/#/cocomod/motor-driver"}
        ],
        module:"主控模組,電機驅動模組",
        content:"通過控制電機,驅動小車前進後退,左右轉向運動"},,
        {id:13,name:"單電機雙足機器人",img:power2,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"電機驅動模組",img:MOTOR,url:"https://help.cocorobo.hk/#/cocomod/motor-driver"}
        ],
        module:"主控模組,電機驅動模組",
        content:"通過單個電機驅動機器人行走"},
        {id:14,name:"激光切割動力小車",img:power3,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/28b17d25-6852-a807-20ca-b6e9093264e3/%E7%BB%84%E8%A3%85%E5%B0%8F%E8%BD%A6~1.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"電機驅動模組",img:MOTOR,url:"https://help.cocorobo.hk/#/cocomod/motor-driver"},
            {name:"藍牙通信模組",img:BLUE,url:"https://help.cocorobo.hk/#/cocomod/bluetooth"}
        ],
        module:"主控模組,電機驅動模組,藍牙通信模組",
        content:"通過藍牙控制電機,驅動小車前進後退,左右轉向運動"},
        {id:15,name:"激光切割風火輪",img:power4,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/5ebb73fe-c197-9564-b234-9eacc423a528/%E9%A3%8E%E7%81%AB%E8%BD%AE_%E8%AF%BE%E7%A8%8B%E9%A2%84%E8%A7%88%E6%BC%94%E7%A4%BA%E8%A7%86%E9%A2%91.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"電機驅動模組",img:MOTOR,url:"https://help.cocorobo.hk/#/cocomod/motor-driver"},
            {name:"藍牙通信模組",img:BLUE,url:"https://help.cocorobo.hk/#/cocomod/bluetooth"}
        ],
        module:"主控模組,電機驅動模組,藍牙通信模組",
        content:"通過控制電機,驅動小車前進後退,左右旋轉運動"},
        {id:16,name:"激光切割爬行機器人",img:power5,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"電機驅動模組",img:MOTOR,url:"https://help.cocorobo.hk/#/cocomod/motor-driver"}
        ],
        module:"主控模組,電機驅動模組",
        content:"通過單個電機驅動爬行機器人前進"},
        {id:17,name:"萬花筒繪圓裝置",img:power6,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"電機驅動模組",img:MOTOR,url:"https://help.cocorobo.hk/#/cocomod/motor-driver"}
        ],
        module:"主控模組,電機驅動模組",
        content:"通過電機驅動轉盤及搖桿,描繪萬花筒圖案還可通過扭轉教學模組上的轉盤調節電機速度畫出不一樣的圖案"},
        {id:18,name:"星球轉動儀",img:power7,
        video:"",
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"電機驅動模組",img:MOTOR,url:"https://help.cocorobo.hk/#/cocomod/motor-driver"}
        ],
        module:"主控模組,電機驅動模組",
        content:"模擬太陽,地球,月亮的轉動關係"},
        {id:19,name:"紙質卡通小車",img:power8,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/3005fcfc-ab44-9389-2a16-10d0c275fed6/%E7%BB%84%E8%A3%85%E6%8A%98%E7%BA%B8%E5%B0%8F%E8%BD%A6~1.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"電機驅動模組",img:MOTOR,url:"https://help.cocorobo.hk/#/cocomod/motor-driver"}
        ],
        module:"主控模組,電機驅動模組",
        content:"通過電機驅動,使小車行走"}
        ],
        [// 音乐
        {id:20,name:"3D打印燈光電子琴",img:music1,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"觸摸感應模組",img:TOUCH,url:"https://help.cocorobo.hk/#/cocomod/touch"},
            {name:"音樂模組",img:MUSIC,url:"https://help.cocorobo.hk/#/cocomod/music"},
            {name:"LED燈屏模組",img:LED,url:"https://help.cocorobo.hk/#/cocomod/led-matrix"}
        ],
        module:"主控模組,觸摸感應模組,音樂模組,LED燈屏模組",
        content:"模擬電子琴,通過觸摸感應模組連出來的導電貼紙,由音樂模組連接揚聲器播放,播放同時LED會有不同燈光"},
        {id:21,name:"3D打印電子琴",img:music2,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"觸摸感應模組",img:TOUCH,url:"https://help.cocorobo.hk/#/cocomod/touch"},
            {name:"音樂模組",img:MUSIC,url:"https://help.cocorobo.hk/#/cocomod/music"}
        ],
        module:"主控模組,觸摸感應模組,音樂模組",
        content:"模擬電子琴,通過觸摸感應模組連出的導電貼紙,由音樂模組連接揚聲器播放"},
        {id:22,name:"激光切割電子鼓",img:music3,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"觸摸感應模組",img:TOUCH,url:"https://help.cocorobo.hk/#/cocomod/touch"},
            {name:"音樂模組",img:MUSIC,url:"https://help.cocorobo.hk/#/cocomod/music"},
            {name:"LED燈屏模組",img:LED,url:"https://help.cocorobo.hk/#/cocomod/led-matrix"}
        ],
        module:"主控模組,觸摸感應模組,音樂模組,LED燈屏模組",
        content:"模擬尤克里裏,通過觸摸感應模組連接對應的鼓器,由音樂模組連接揚聲器播放,同時根據不同的鼓聲,LED模組發出不同的燈光"},
        {id:23,name:"激光切割尤克里裏",img:music4,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/9d8a9b24-d6fb-29a5-f21d-f0e49570ae1e/%E7%BB%84%E8%A3%85%E5%B0%A4%E5%85%8B%E9%87%8C%E9%87%8C.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"觸摸感應模組",img:TOUCH,url:"https://help.cocorobo.hk/#/cocomod/touch"},
            {name:"音樂模組",img:MUSIC,url:"https://help.cocorobo.hk/#/cocomod/music"},
            {name:"LED燈屏模組",img:LED,url:"https://help.cocorobo.hk/#/cocomod/led-matrix"}
        ],
        module:"主控模組,觸摸感應模組,音樂模組,LED燈屏模組",
        content:"模擬尤克里裏,通過觸摸感應模組連出的導電源,由音樂模組連接揚聲器播放"},
        {id:24,name:"紙模型電子琴",img:music5,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/19191044-b7ed-5ba9-2c9c-fd44d881311d/%E7%BB%84%E8%A3%85%E7%BA%B8%E6%A8%A1%E7%94%B5%E5%AD%90%E7%90%B4.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"觸摸感應模組",img:TOUCH,url:"https://help.cocorobo.hk/#/cocomod/touch"},
            {name:"音樂模組",img:MUSIC,url:"https://help.cocorobo.hk/#/cocomod/music"}
        ],
        module:"主控模組,觸摸感應模組,音樂模組",
        content:"模擬電子琴,通過觸摸感應模組連出的導電貼紙,由音樂模組連接揚聲器播放"}
        ],
        [// 灯光
        {id:25,name:"2×2燈屏",img:led1,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"轉接模組A2",img:TRANSFERA2,url:"https://help.cocorobo.hk/#/cocomod/hub"},
            {name:"LED燈屏模組",img:LED,url:"https://help.cocorobo.hk/#/cocomod/led-matrix"}
        ],
        module:"主控模組,LED燈屏模組,轉接模組A2",
        content:"面板由四塊LED燈屏模組組成,可演示出不同效果的彩屏"},
        {id:26,name:"3D打印月球燈",img:led2,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/bd51b7be-96e0-08f7-ff2c-f2275922b9673//04P25.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"教學模組",img:TEACHING,url:"https://help.cocorobo.hk/#/cocomod/sensor-101"},
            {name:"LED燈屏模組",img:LED,url:"https://help.cocorobo.hk/#/cocomod/led-matrix"}
        ],
        module:"主控模組,LED燈屏模組,教學模組",
        content:"可調節教學模組按鍵,LED燈屏發出不同顏色的燈光"},
        {id:27,name:"激光切割百變燈臺",img:led3,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/fbfc9b2f-679e-a319-1c74-2d18a1880da32//%E7%BB%84%E8%A3%85%E6%BF%80%E5%85%89%E5%88%87%E5%89%B2%E5%8F%B0%E7%81%AF.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"LED燈屏模組",img:LED,url:"https://help.cocorobo.hk/#/cocomod/led-matrix"}
        ],
        module:"主控模組,LED燈屏模組",
        content:"百變葉外形,LED燈屏發出光照"},
        {id:28,name:"激光切割觸摸檯燈",img:led4,
        video:"",
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"觸摸感應模組",img:TOUCH,url:"https://help.cocorobo.hk/#/cocomod/touch"},
            {name:"LED燈屏模組",img:LED,url:"https://help.cocorobo.hk/#/cocomod/led-matrix"}
        ],
        module:"主控模組,LED燈屏模組,觸摸感應模組",
        content:"由六個觸摸控制鍵來調節檯燈的不同亮度"}
        ],
        [// 人机交互
        {id:29,name:"互動手套",img:interaction1,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"觸摸感應模組",img:TOUCH,url:"https://help.cocorobo.hk/#/cocomod/touch"}
        ],
        module:"主控模組,觸摸感應模組",
        content:"通過觸摸模組上的觸摸針腳,連接到各手指,可代替電腦上的按鍵進行操控"},
        {id:30,name:"激光切割手柄",img:interaction2,
        video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/fbfa9282-092d-cd3c-78fa-5de6c68a4c261//%E6%89%8B%E6%9F%84%E5%88%B6%E4%BD%9C.mp4',
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"觸摸感應模組",img:TOUCH,url:"https://help.cocorobo.hk/#/cocomod/touch"}
        ],
        module:"主控模組,觸摸感應模組",
        content:"通過觸摸模組上的觸摸針腳,連接到激光切割手柄,可代替鍵盤上的按鍵進行操控"},
        {id:31,name:"紙質遊戲手柄",img:interaction3,
        moduleImgs:[
            {name:"主控模組",img:MAIN,url:"https://help.cocorobo.hk/#/cocomod/main-controller"},
            {name:"觸摸感應模組",img:TOUCH,url:"https://help.cocorobo.hk/#/cocomod/touch"}
        ],
        module:"主控模組,觸摸感應模組",
        content:"通過觸摸模組上的觸摸針腳,連接到紙質遊戲手柄,可代替鍵盤上的按鍵進行操控"}
        ],
    ],
    two:[
        [
            {id:32,name:"雙足機器人",img:metal1,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組",
            content:"<span class='contentTitle'>語音功能：</span>通過語音指令控制機器人四個舵機,操控雙足機器人做出對應的動作"},
            {id:33,name:"四足機器人",img:metal2,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",content:"<span class='contentTitle'>顏色識別功能：</span>通過攝像頭識別方塊的顏色,四足機器人分析不同顏色做不同的動作</br><span class='contentTitle'>語音識別功能：</span>通過語音指令控制機器人八個舵機,操控四足機器人做出對應的動作"},
            {id:34,name:"四足機器人加強版",img:metal3,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",
            content:"<span class='contentTitle'>顏色識別功能：</span>通過攝像頭識別方塊的顏色,四足機器人分析不同顏色做不同的動作</br><span class='contentTitle'>語音識別功能：</span>通過語音指令控制機器人八個舵機,操控四足機器人做出對應的動作"},
            {id:35,name:"金屬結構麥輪車",img:metal4,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"電機驅動模組",img:MOTORTWO,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E9%A6%AC%E9%81%94%E9%A9%85%E5%8B%95%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,電機驅動模組,攝像頭模組",
            content:"<span class='contentTitle'>物體跟蹤功能：</span>基於模型訓練,通過攝像頭識別到物體,並自動跟隨物體"},
            {id:36,name:"垃圾分類",img:metal5,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",
            content:"<span class='contentTitle'>物體識別功能：</span>基於模型訓練,通過攝像頭識別到物體,分析該物體類別,自動打開對應的垃圾箱"},
            {id:37,name:"瓦力機器人",img:metal6,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"電機驅動模組",img:MOTORTWO,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E9%A6%AC%E9%81%94%E9%A9%85%E5%8B%95%E6%A8%A1%E7%B5%84"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,電機驅動模組",
            content:"<span class='contentTitle'>語音功能：</span>通過語音指令控制機器人,操控瓦力機器人做出對應的動作"},
            {id:38,name:"智能機械臂",img:metal7,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",
            content:"<span class='contentTitle'>顏色識別功能：</span>通過攝像頭識別方塊的顏色,機械臂進行抓取方塊並將不同顏色的方塊放置不同的位置"},
            {id:39,name:"智能閘門",img:metal8,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",
            content:"<span class='contentTitle'>人臉識別功能：</span>通過攝像頭拍攝到人臉,自動識別人臉特徵並打開門"},
            {id:40,name:"機械狗",img:metal9,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"}
            ],
            module:"AI模組,LCD熒幕模組,攝像頭模組",
            content:"<span class='contentTitle'>語音識別功能：</span>通過語音指令控制機器狗,操控機械狗做出對應的動作</br><span class='contentTitle'>藍牙控制功能：</span>通過藍牙通訊控制機器狗,操控機械狗做出對應的動作"}
        ],
        [
            {id:41,name:"寶麗來相機",img:wooden1,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"}
            ],
            module:"AI模組,LCD熒幕模組,攝像頭模組",
            content:"<span class='contentTitle'>相機功能：</span>通過控制AI模組按鍵,實現拍照並存儲功能"},
            {id:42,name:"垃圾分類",img:wooden2,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",
            content:"<span class='contentTitle'>物體識別功能：</span>基於模型訓練,通過攝像頭識別到物體,分析該物體類別,自動打開對應的垃圾箱"},
            {id:43,name:"木製麥輪小車",img:wooden4,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"電機驅動模組",img:MOTORTWO,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E9%A6%AC%E9%81%94%E9%A9%85%E5%8B%95%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,電機驅動模組,攝像頭模組",
            content:"<span class='contentTitle'>物體跟蹤功能：</span>基於模型訓練,通過攝像頭識別到物體,並自動跟隨物體"},
            {id:44,name:"木製麥輪機械臂小車",img:wooden3,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"電機驅動模組",img:MOTORTWO,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E9%A6%AC%E9%81%94%E9%A9%85%E5%8B%95%E6%A8%A1%E7%B5%84"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,電機驅動模組,攝像頭模組,擴展轉接模組",
            content:"<span class='contentTitle'>物體識別功能：</span>基於模型訓練,通過攝像頭識別到物體,自動跟隨物體並抓取"},
            {id:45,name:"雙足機器人",img:wooden5,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組",
            content:"<span class='contentTitle'>語音功能：</span>通過語音指令控制機器人四個舵機,操控雙足機器人做出對應的動作"},
            {id:46,name:"瓦力機器人",img:wooden6,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"},
                {name:"電機驅動模組",img:MOTORTWO,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E9%A6%AC%E9%81%94%E9%A9%85%E5%8B%95%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,電機驅動模組",
            content:"<span class='contentTitle'>語音功能：</span>通過語音指令控制機器人,操控瓦力機器人做出對應的動作"},
            {id:47,name:"音樂播放器",img:wooden7,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"}
            ],
            module:"AI模組,LCD熒幕模組,攝像頭模組",
            content:"<span class='contentTitle'>音頻分析功能：</span>通過分析環境聲音大小,LCD熒幕呈現模擬聲音大小光環"},
            {id:48,name:"雲臺追蹤",img:wooden8,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",
            content:"<span class='contentTitle'>人臉識別功能：</span>通過攝像頭拍攝到人臉,自動識別人臉特徵並追蹤人臉運動"},
            {id:49,name:"招財貓",img:wooden9,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",
            content:"<span class='contentTitle'>人臉識別功能：</span>通過攝像頭拍攝到人臉,自動識別人臉特徵並自動招手"},
            {id:50,name:"智能機械臂",img:wooden10,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",
            content:"<span class='contentTitle'>顏色識別功能：</span>通過攝像頭識別方塊的顏色,機械臂進行抓取方塊並將不同顏色的方塊放置不同的位置"},
            {id:51,name:"智能閘門",img:wooden11,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"擴展轉接模組",img:EXTENSION,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E6%93%B4%E5%B1%95%E8%BD%89%E6%8E%A5%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,擴展轉接模組,攝像頭模組",
            content:"<span class='contentTitle'>人臉識別功能：</span>通過攝像頭拍攝到人臉,自動識別人臉特徵並打開門"},
            {id:52,name:"自動駕駛遙控車",img:wooden12,
            moduleImgs:[
                {name:"AI模組",img:AI,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E7%B5%84"},
                {name:"LCD熒幕模組",img:SCREEN,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E8%9E%A2%E5%B9%95%E6%A8%A1%E7%B5%84"},
                {name:"攝像頭模組",img:CAMERA,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E7%9B%B8%E6%A9%9F"},
                {name:"電機驅動模組",img:MOTORTWO,url:"https://x-help.cocorobo.hk/#/md/%E4%BD%BF%E7%94%A8%E9%A6%AC%E9%81%94%E9%A9%85%E5%8B%95%E6%A8%A1%E7%B5%84"}
            ],
            module:"AI模組,LCD熒幕模組,電機驅動模組,攝像頭模組",
            content:"<span class='contentTitle'>物體識別功能：</span>基於模型訓練,通過攝像頭識別到路標,並執行路標的指示運動"}
        ]
    ]
}

export default demo