import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/orderItem.css';


class OrderItem extends React.Component {
  constructor(props){
    super(props);
    this.removeItem = this.removeItem.bind(this);
  }
  update(e){
    if(parseInt(e.target.value) == 0 ){
      this.props.store.order.delete(e.target.parentNode.children[0].innerHTML);
    }else if(parseInt(e.target.value) > 0){
      if(parseInt(e.target.value) > 999){
        let unitPrice = this.props.store.order.get(e.target.parentNode.children[0].innerHTML)[0];
        this.props.store.editItem(e.target.parentNode.children[0].innerHTML,999 ,unitPrice);
      }else{
        let unitPrice = this.props.store.order.get(e.target.parentNode.children[0].innerHTML)[0];
        let content = [unitPrice, e.target.value > 0 ? e.target.value-0:0];
        this.props.store.order.set(e.target.parentNode.children[0].innerHTML,content);
      }
    }
  }
  prevent(e){
    if(e.keyCode == 8){
      if(parseInt(e.target.value) < 10 && parseInt(e.target.value) !== 1){
        let unitPrice = this.props.store.order.get(e.target.parentNode.children[0].innerHTML)[0];
        let content = [unitPrice,1];
        this.props.store.order.set(e.target.parentNode.children[0].innerHTML, content);
      }
    }
  }
  removeItem(e){
    let targetName = e.target.parentNode.getElementsByClassName('OrderItem__name')[0].innerHTML;
    this.props.store.order.delete(targetName);
  }
  render () {
    return(
      <div className="OrderItem">
        <div className='OrderItem__name'>{this.props.name}</div>
        <input value={this.props.value} type='number' className='OrderItem__value' onChange={this.update.bind(this)} onKeyDown={this.prevent.bind(this)}></input>
        <button id='OrderItem__remove' className='OrderItem__remove' onClick={this.removeItem}>刪除</button>
      </div>
    )
  }
}

export default OrderItem;
