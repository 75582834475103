import React, { Component } from 'react';
import TitleMain from './titleMain';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import '../assets/css/downLoad.css'



class Download extends Component {
    state = {
        system: "",
        system1: "",
        selectList1Windows: [
            {
                id: 0,
                value: "https://api.cocorobo.hk/releases/uploaderv2/download/windows",
                name: "v3.0.6"
            },
        ],
        selectList1MacOS: [
            {
                id: 0,
                value: "https://cocorobo.hk/downloads/uploaderv2_mac_v3.0.8.pkg",
                name: "v3.0.8"
            },
        ],
        uploaderVersion1: [],
        uoloaderLink1: "",
        uploaderName1: "",
        selectList2Windows: [
            {
                id: 0,
                value: "https://cocorobo.hk/downloads/CocoBlocklyXUploaderInstallerv1.1.0.exe",
                name: "v1.1.0"
            }, {
                id: 1,
                value: "https://cocorobo.hk/downloads/CocoBlocklyXUploaderInstallerv1.0.18.exe",
                name: "v1.0.18"
            }
        ],
        selectList2MacOS: [
            {
                id: 0,
                value: "https://cocorobo.hk/downloads/pythonuploaderv1.1.4_mac.pkg",
                name: "v1.1.4"
            },
            {
                id: 2,
                value: "https://cocorobo.hk/downloads/pythonuploaderv1.1.0_mac.pkg",
                name: "v1.1.0"
            }, {
                id: 3,
                value: "https://cocorobo.hk/downloads/CocoBlocklyXUploaderInstallerv1.0.18.pkg",
                name: "v1.0.18"
            }
        ],
        uploaderVersion: [],
        uoloaderLink: "",
        uploaderName: ""
    }
    componentDidMount = () => {
        // console.log(document.getElementsByTagName('footer')[0].style)
        document.getElementsByTagName('footer')[0].style.marginTop = 0;
        let PCSystem = navigator.userAgentData.platform;
        let version = this.state.selectList2Windows
        let version1 = this.state.selectList1Windows
        if (PCSystem !== "Windows") {
            PCSystem = "MacOS"
            version = this.state.selectList2MacOS
            version1 = this.state.selectList1MacOS
        }
        this.setState({
            system1: PCSystem,
            system: PCSystem,
            uploaderVersion: version,
            uploaderName: version[0].name,
            uoloaderLink: version[0].value,
            uploaderVersion1: version1,
            uploaderName1: version1[0].name,
            uoloaderLink1: version1[0].value
        })
    }
    handleChange1 = event => {
        console.log(event.target.value)
        let version = []
        if (event.target.value === "Windows") {
            version = this.state.selectList1Windows
        } else {
            version = this.state.selectList1MacOS
        }
        this.setState({
            system1: event.target.value,
            uploaderVersion1: version,
            uoloaderLink1: version[0].value,
            uploaderName1: version[0].name
        })
    }

    handleChange = (event) => {
        let version = []
        if (event.target.value === "Windows") {
            version = this.state.selectList2Windows
        } else {
            version = this.state.selectList2MacOS
        }
        this.setState({
            system: event.target.value,
            uploaderVersion: version,
            uoloaderLink: version[0].value,
            uploaderName: version[0].name
        },()=>{
            console.log(this.state,version)
        })
    }

    handleChangeVersion = (event) => {
        const { uploaderVersion } = this.state;
        let link = uploaderVersion.find(item => item.name == event.target.value)
        this.setState({
            uoloaderLink: link.value,
            uploaderName: event.target.value
        })
    }

    handleChangeVersion1 = (event) => {
        const { uploaderVersion1 } = this.state;
        let link = uploaderVersion1.find(item => item.name == event.target.value)
        this.setState({
            uoloaderLink1: link.value,
            uploaderName1: event.target.value
        })
    }
    componentWillUnmount = () => {

        document.getElementsByTagName('footer')[0].style.marginTop = 100 + 'px';
    }


    render() {
        const {system1,system,uoloaderLink,uoloaderLink1,uploaderName,uploaderName1,uploaderVersion,uploaderVersion1} = this.state
        return (
            <div className="downLoad">
                {/* <TitleMain name='下載' /> */}
                <div className="top">
                    <h1 style={{ fontWeight: "600" }}>CocoBlockly</h1>
                    <p>基於 Blockly 的積木式硬件編程工具</p>
                </div>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/6.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">CocoBlockly 上傳插件</span>
                        <p className="content">爲了能夠讓 CocoBlockly 能夠從瀏覽器和主控模組進行通信，我們推出一款跨平臺的上傳插件（Uploader），用戶下載該插件並安裝，隨後即可訪問 CocoBlockly，對我們的電子模組進行上傳編程。</p>
                        <p className="setting">支持的操作系統：</p>
                        <span>Windows / macOS</span>
                        <div className="downing">
                            <InputLabel id="demo-simple-select-name">系統：</InputLabel>
                            <Select style={{ marginLeft: '5px' }}
                                labelId="demo-select-small-name"
                                id="demo-select-small"
                                value={system1}
                                name="Age"
                                onChange={this.handleChange1}
                            >
                                <MenuItem value="Windows">Windows</MenuItem>
                                <MenuItem value="MacOS">MacOS</MenuItem>
                            </Select>

                            <InputLabel id="demo-simple-select-name" style={{ marginLeft: '20px' }}>版本：</InputLabel>
                            <Select style={{ marginLeft: '5px' }}
                                labelId="demo-select-small-name"
                                id="demo-select-small"
                                value={uploaderName1}
                                name="uploaderName"
                                onChange={this.handleChangeVersion1}
                            >
                                {uploaderVersion1.map(item => {
                                    return <MenuItem value={item.name} key={item.value}>{item.name}</MenuItem>
                                })}
                            </Select>
                            <a href={uoloaderLink1} style={{ marginLeft: '20px' }}>
                                <button>下載</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/CocoBlockly_X_Uploader.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">CocoBlockly X 二代上傳插件</span>
                        <p className="content">爲了能夠讓 CocoBlockly X 能夠從瀏覽器和我們二代的 AI 和 IoT 模組進行通信，進行上傳和運行的操作，我們推出一款跨平臺的上傳插件，用戶下載該插件並安裝，隨後便可以對我們的電子模組進行上傳編程。</p>
                        <p className="setting">支持的操作系統：</p>
                        <span>Windows / macOS</span>
                        <div className="downing">

                            <InputLabel id="demo-simple-select-name">系統：</InputLabel>
                            <Select style={{ marginLeft: '5px' }}
                                labelId="demo-select-small-name"
                                id="demo-select-small"
                                value={system}
                                name="Age"
                                onChange={this.handleChange}
                            >
                                <MenuItem value="Windows">Windows</MenuItem>
                                <MenuItem value="MacOS">MacOS</MenuItem>
                            </Select>

                            <InputLabel id="demo-simple-select-name" style={{ marginLeft: '20px' }}>版本：</InputLabel>
                            <Select style={{ marginLeft: '5px' }}
                                labelId="demo-select-small-name"
                                id="demo-select-small"
                                value={uploaderName}
                                name="uploaderName"
                                onChange={this.handleChangeVersion}
                            >
                                {uploaderVersion.map(item => {
                                    return <MenuItem value={item.name} key={item.value}>{item.name}</MenuItem>
                                })}
                            </Select>
                            <a href={uoloaderLink} style={{ marginLeft: '20px' }}>
                                <button>下載</button>
                            </a>
                            {/* <a href="https://cocorobo.hk/downloads/CocoBlocklyXUploaderInstallerv1.0.18.exe">
                                <button>下載 Windows 版本(v1.0.18)</button>
                            </a> &nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="down_time">更新於2024/03/01</span>
                            <a href="https://cocorobo.hk/downloads/pythonuploaderv1.1.4_mac.pkg">
                                <button>下載 MacOS 版本(v1.1.4)</button>
                            </a>
                            <span className="down_time1">更新於2024/04/27</span> */}
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/cocorobolixianban2.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">CocoBlockly X 離線版本</span>
                        <p className="content">爲了能夠讓用戶更方便的使用該產品，在網絡較慢或者其他情況下，可以更好使用。</p>
                        <p className="setting">支持的操作系統：</p>
                        <span>Windows</span>
                        <div className="downing">
                            <a href="https://cocorobo.hk/downloads/CocoRoboXDeskTop Installer v1.0.4.exe">
                                <button>下載 CocoBlockly X 離線版本（v1.0.4）</button>
                            </a>
                            <span className="down_time2">更新於2021/12/01</span>
                        </div>
                    </div>
                </div>
                <h1 className="titleName">推薦瀏覽器</h1>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/3.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">下載谷歌瀏覽器</span>
                        <p className="content">使用谷歌瀏覽器，在線編程體驗更流暢</p>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/com.android.chrome_v103.0.5060.129_itmop.com.apk" className='android'>
                                <button>下載Android版本</button>
                            </a>
                            <a target="_blank" href="https://www.google.cn/chrome/index.html">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/7.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">360安全瀏覽器</span>
                        <p className="content">CocoBlockly 編程工具在 360 極速瀏覽器中運行，讓你在線編程體驗進行流暢</p>
                        <div className="downing">
                            <a target="_blank" href="https://browser.360.cn">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
                <h1 className="titleName">輔助工具及軟件</h1>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/4.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">TeamViewer 遠程協助軟件</span>
                        <p className="content">隨時隨地連接到遠程桌面電腦，讓遠程連接過程更加的快速和安全，輕鬆訪問文件、網絡及程序</p>
                        <div className="downing">
                            <a target="_blank" href="https://www.teamviewer.cn/cn/download/">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/5.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">ZOOM 遠程會議軟件</span>
                        <p className="content">多人手機雲視頻會議軟件，提供兼備高清視頻與移動網絡會議功能的免費雲視頻通話</p>
                        <div className="downing">
                            <a target="_blank" href="https://zoom.com.cn/download">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/8.jpg')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">向日葵遠程協助軟件</span>
                        <p className="content">免費的集遠程控制電腦手機、遠程桌面連接、遠程開機、遠程管理、支持內網穿透的一體化遠程控制管理工具軟件</p>
                        <div className="downing">
                            <a target="_blank" href="https://sunlogin.oray.com/download/">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/9.jpg')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">ToDesk 遠程協助軟件</span>
                        <p className="content">穩定流暢的遠程控制,遠程辦公,遠程協助軟件.採用端對端加密,讓每一次遠程訪問都安全可靠</p>
                        <div className="downing">
                            <a target="_blank" href="https://www.todesk.com/download.html">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Download;