import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import '../assets/css/tabBar.css';

import creative from '../assets/img/curriculumKit__creative.svg';
import car from '../assets/img/curriculumKit__car.svg';
import robot from '../assets/img/curriculumKit__robot.svg';
import smartHome from '../assets/img/curriculumKit__smartHome.svg';


import Tilt from 'react-tilt';



class TabBar extends React.Component {
  render () {
    return(
      <div className="TabBar" onClick={this.props.fn}>
        <Link className='TabBar__link' to='/curriculumKit/0'>
          <Tilt className="Tilt TabBar__tilt TabBar__tilt--0" options={{ max : 25 }} >
            <img src={creative} alt="creative" width="100%" className='TabBar__image'/>
            <div className='TabBar__tab curriculum_0' style={{backgroundColor:'#FF7F66'}}>創意電子</div>
          </Tilt>
        </Link>
        <Link className='TabBar__link' to='/curriculumKit/1'>
          <Tilt className="Tilt TabBar__tilt TabBar__tilt--1" options={{ max : 25 }} >
            <img src={car} alt="car" width="100%" className='TabBar__image'/>
            <div className='TabBar__tab curriculum_1' style={{backgroundColor:'#7ECEFD'}}>動力小車</div>
          </Tilt>
        </Link>
        <Link className='TabBar__link' to='/curriculumKit/2'>
        <Tilt className="Tilt TabBar__tilt TabBar__tilt--2" options={{ max : 25 }} >
          <img src={robot} alt="robot" width="100%" className='TabBar__image'/>
          <div className='TabBar__tab curriculum_2' style={{backgroundColor:'#502868'}}>機械達人</div>
        </Tilt>
        </Link>
        <Link className='TabBar__link' to='/curriculumKit/3'>
        <Tilt className="Tilt TabBar__tilt TabBar__tilt--3" options={{ max : 25 }} >
          <img src={smartHome} alt="robot" width="100%" className='TabBar__image'/>
          <div className='TabBar__tab curriculum_3' style={{backgroundColor:'#57AF6D'}}>智能家居</div>
        </Tilt>
        </Link>
      </div>
    )
  }
}

export default TabBar;
