import React ,{Component} from 'react';
import '../assets/css/demo.css';
//component
import TitleMain from './titleMain';
//data
import modules from './modules';
import sets from './sets';
//
import {Link} from 'react-router-dom';

//image
import picture from '../assets/img/picture.svg';
import wiki from '../assets/img/wiki.svg';
import play from '../assets/img/play_btn.svg';

//plugin
import scrollToComponent from 'react-scroll-to-component';

//mobx
import {observer} from 'mobx-react';
import store from '../store/orderStore.js';

class Demo extends Component{
	constructor(props){
		super(props);
		this.state ={
			moduleMain:modules.m,
			moduleInput: modules.i,
			moduleOutput: modules.o,
			moduleCommunication:modules.c,
			moduleAdapter:modules.a,
			moduleOthers:modules.z,
			img:modules.m.m0.img,
			title:modules.m.m0.name,
			content:modules.m.m0.desc,
			wiki:modules.m.m0.wiki,
			hasVideo:false,
			video:'',
		};
		this.info = this.info.bind(this);
		this.modulesClip = this.modulesClip.bind(this);
		this.hasVideo = this.hasVideo.bind(this);
	}
	info(event){
		if(event.target.nodeName === 'IMG'){
			let getName = event.target.alt;
			let getCat = event.target.alt.charAt(0);
			store.cocomodFocusModuleName = getName;
			store.cocomodFocusModuleCat = getCat;
			this.hasVideo(store.cocomodFocusModuleCat,store.cocomodFocusModuleName);
			this.setState({
				img:modules[getCat][getName].img,
				title:modules[getCat][getName].name,
				content:modules[getCat][getName].desc,
				wiki:modules[getCat][getName].wiki,
			});
			}
			scrollToComponent(this.result, { offset: 0, align: 'top', duration: 300, ease:'inCirc'});
	}
	hasVideo(cat,name){
		if(typeof modules[cat][name].video == 'undefined'){
			this.setState({
				hasVideo:false,
			})
		}else{
			this.setState({
				hasVideo:true,
			})
		}
	}
	modulesClip(){
		this.setState({
			video:modules[store.cocomodFocusModuleCat][store.cocomodFocusModuleName].video,
		})
	}
	render(){
		return(
				<div>
					<TitleMain name='CocoMod &#8482; 模組' />
					<div className='demo'>
						<div className='result' ref={(block) => { this.result = block; }}>
						 <header>介紹區</header>
						 <img src={this.state.img} alt='moduleImage' style={{padding:"10%",paddingBottom:0,}}/>
						 <h5 style={{color:'#666'}}>{this.state.title}</h5>
						 <p>{this.state.content}</p>
						 <div style={{display:'flex',justifyContent:'center'}}>
							<a href={this.state.wiki} target='_blank' style={{color:'#000'}}>
								<div>
									<img src={wiki} style={{verticalAlign:'top',margin:'10px 20px'}} width='20px'/>
									<span style={{display:'block'}}>Wiki</span>
								</div>
							</a>
						 	{this.state.hasVideo?
							<a href='#modal-clip' onClick={this.modulesClip} uk-toggle="target: #modal-clip" style={{color:'#000'}}>
								<div>
									<img src={play} style={{verticalAlign:'top',margin:'10px 20px'}} width='20px'/>
									<span style={{display:'block'}}>Play</span>
								</div>
							</a>
							:''}
							</div>
						</div>
						<div className='modules' onClick={this.info}>
							<div className='moduleM module' >
								<header>主控模組</header>
								<ul>
								{Object.keys(this.state.moduleMain).map((x)=> (<li key={x} className={x} ><img id={`d_${x}`} alt={x} src={this.state.moduleMain[x].img} width='100%'/><span>{this.state.moduleMain[x].name}</span></li>))}
								</ul>
							</div>
							<div className='moduleI module'>
								<header>輸入模組</header>
								<ul>
								{Object.keys(this.state.moduleInput).map((x)=> (<li key={x} className={x} ><img id={`d_${x}`} alt={x} src={this.state.moduleInput[x].img} width='100%'/><span>{this.state.moduleInput[x].name}</span></li>))}
								</ul>
							</div>
							<div className='moduleO module'>
								<header>輸出模組</header>
								<ul>
								{Object.keys(this.state.moduleOutput).map((x)=> (<li key={x} className={x}><img id={`d_${x}`} alt={x} src={this.state.moduleOutput[x].img} width='100%'/><span>{this.state.moduleOutput[x].name}</span></li>))}
								</ul>
							</div>
							<div className='moduleT module'>
								<header>轉接模組</header>
								<ul>
								{Object.keys(this.state.moduleAdapter).map((x)=> (<li key={x} className={x}><img id={`d_${x}`} alt={x} src={this.state.moduleAdapter[x].img} width='100%'/><span>{this.state.moduleAdapter[x].name}</span></li>))}
								</ul>
							</div>
							<div className='moduleC module'>
								<header>通訊模組</header>
								<ul>
								{Object.keys(this.state.moduleCommunication).map((x)=> (<li key={x} className={x}><img id={`d_${x}`} alt={x} src={this.state.moduleCommunication[x].img} width='100%'/><span>{this.state.moduleCommunication[x].name}</span></li>))}
								</ul>
							</div>
							<div className='register module'>
								<header>其他模組</header>
								<ul>
								{Object.keys(this.state.moduleOthers).map((x)=> (<li key={x} className={x}><img id={`d_${x}`} alt={x} src={this.state.moduleOthers[x].img} width='100%'/><span>{this.state.moduleOthers[x].name}</span></li>))}
								</ul>
							</div>
						</div>
					</div>
					{/*Modal  mark: change id */}
					<div id='modal-clip' uk-modal='true' className='uk-flex-top'>
						<div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
							<video controls={true} playsInline uk-video='true' width="100%" src={this.state.video}></video>
							<button className="uk-modal-close-outside"  uk-icon='close'>X</button>
						</div>
					</div>
				</div>
		)
	}
}

export default Demo;
