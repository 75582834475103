import React ,{Component} from 'react';
import TitleMain from '../components/titleMain';
import CardSmallFn from '../components/card_small_function';
import ComingSoon from '../components/comingSoon';
import '../assets/css/discover.css';
//import image
import project_img from '../assets/img/discoverProject.svg';
import model_img from '../assets/img/discoverModel.svg';
import tutorial_img from '../assets/img/discoverTutorial.svg';
import discover_img_1 from '../assets/img/discover_img_1.jpg';
import UGCHeaderList from '../components/UGCHeaderList';
import {Route,Link} from 'react-router-dom';
import UGCUserContent from '../components/UGCUserContent';
import discover from '../data/discover';


let page = 'discover' ;
let collections = discover ;
let title = '發現';
let headerList = [
	{
		cn:'項目',
		en:'project',
		img:project_img
	},
	{
		cn:'模型',
		en:'model',
		img:model_img
	}
 ]

class Discover extends Component{
	constructor(props){
		super(props);
		// this.state ={
		// 	projectToRender:projectToRender,
		// }
	}
	toggleList(event){
		if(event.target.nodeName === 'IMG'){
			let getName = event.target.id;
			document.querySelectorAll('.UGCHeaderList--active')[0].className='UGCHeaderList';
			document.getElementById(getName).className ='UGCHeaderList--active';
		}
	}
	render(){
		return(
			<div>
				<TitleMain name={title} />
				{/*<ComingSoon />*/}
				<div className='UGCHeaderLists'>
					<header onClick={this.toggleList.bind(this)}>
            <Link to={`/${page}/${headerList[0].en}`}><UGCHeaderList name={headerList[0].en} img={headerList[0].img} nameCN={headerList[0].cn} active={true}/></Link>
            <Link to={`/${page}/${headerList[1].en}`}><UGCHeaderList name={headerList[1].en} img={headerList[1].img} nameCN={headerList[1].cn} /></Link>
					</header>
					<Route path={`/${page}/:id`} render={UGCUserContentChild} />
				</div>
			</div>
		)
	}
}
const UGCUserContentChild=({match})=>(
	<UGCUserContent data={collections} name={match.params.id}/>
)
export default Discover;
