import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/inquiryForm.css'
//data
import basicKit from '../data/basicKit'

//components
import Button from './button';

//material-ui
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//axios
import axios from 'axios';
//config
import config from '../config';
//mobx
import {observer} from 'mobx-react'

@observer
class InquiryForm extends React.Component {
  state = {
    emailError:false,
    submitError:false,
    emailValid:false,
    contactsValid:false,
    schoolNameValid:false,

  }
  emailPrompt = (e) => {
    if(this.validateEmail(e.target.value)){
      this.setState({
        emailError:false
      })
    }else if(e.target.value === ''){
      this.setState({
        emailError:false
      })
    }else{
      this.setState({
        emailError:true
      })
    }
  }
  removePrompt = (e) => {
    let index = e.target.id.indexOf('__')+2;
    let obj = e.target.id.slice(index);
    if(e.target.value !== ''){
      this.setState({
        [`${obj}Valid`]:true
      })
    }else{
      this.setState({
        [`${obj}Valid`]:false,
      })
    }

  }
  submitForm = () => {
    let email = document.getElementById('Inquiry__email').value;
    let schoolName = document.getElementById('Inquiry__schoolName').value;
    let telephone = parseInt(document.getElementById('Inquiry__telephone').value);
    let contacts = document.getElementById('Inquiry__contacts').value;
    let list = this.props.store.getList();
    if(schoolName!==''&& contacts!==''&&this.validateEmail(email)){
      axios.post(`${config.server}/inquiry/submit`,{
        email,
        schoolName,
        telephone,
        contacts,
        list,
      })
      .then((res) => {
        res.data === 'OK' && window.alert('詢價單已發送');
      })
      .catch((err) => {
        if(err.response.status){
          this.setState({
            submitError:true
          })
        }
      })
    }else{
      this.validateEmpty();
    }
  }
  validateEmpty = () => {
    let email = document.getElementById('Inquiry__email').value;
    let schoolName = document.getElementById('Inquiry__schoolName').value;
    let contacts = document.getElementById('Inquiry__contacts').value;
    let {schoolNameValid, contactsValid , emailValid }  = this.state;
    schoolName === '' ? this.setState({ schoolNameValid :false}):this.setState({ schoolNameValid :true});
    contacts === '' ? this.setState({ contactsValid :false}):this.setState({ contactsValid :true});
    this.validateEmail(email) ? this.setState({emailValid :true,emailError:false}):this.setState({emailValid :false,emailError:true});
    if(!schoolNameValid || contactsValid || !emailValid){
      this.setState({
        submitError:true
      })
    }
  }
  onBlur=(e)=>{
    let reg = /^(0|[1-9][0-9]*)$/;
    if(reg.test(e.target.value)){
      this.props.store[e.target.name] = parseInt(e.target.value);
    }else{
      alert('數據錯誤,請輸入大於0的正整數!')
      e.target.value=0;
    }
  }
  updateVal = (e) => {
    this.props.store[e.target.name] = parseInt(e.target.value);
  }
  updateCheck = (e) => {
    this.props.store[e.target.name] = e.target.checked;
  }
  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
  }
  render () {
    return(
      <div className='InquiryForm'>
        <p className="InquiryForm__brief">
          CocoRobo提供多款入門套件（Basic Kit）及高階百變智造套件（Full Kit），並搭配系統的課程學習，啟發無限創造力。入門套件（Basic Kit）：每款Basic Kit均包含6個課時以內的教學大綱及課程材料。
        </p>
        <table className='InquiryForm__category'>
          <tbody>
          <tr>
            <th>名稱</th>
            <th>單價（港幣）</th>
            <th>数量</th>
            <th>询价</th>
          </tr>
          {Object.keys(basicKit).map((x,y) => (
            <React.Fragment key={`basicKit__${y}`}>
            <tr>
              <td><a href={`/cocokit#`+basicKit[x].name} target="_blank">{basicKit[x].nameCN}</a></td>
              <td>${basicKit[x].price}</td>
              <td>
                <input
                  onBlur={this.onBlur}
                  id={`basicKit__${basicKit[x].name}`}
                  type='number'
                  min='0'
                  max='99'
                  onChange={this.updateVal}
                  name={basicKit[x].name}
                  />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>{basicKit[x].extension}</td>
              <td></td>
              <td></td>
              <td>
                <input
                  type='checkbox'
                  name={`${basicKit[x].name}Extension`}
                  onChange = {this.updateCheck}
                  />
              </td>
            </tr>
            </React.Fragment>
          ))}
          <tr>
            <td><a href={`/cocokit#makerKit`} target="_blank">百變智造套件(Full Kit)</a></td>
            <td>$1450</td>
            <td>
              <input
                onBlur={this.onBlur}
                type='number'
                min='0'
                max='99'
                onChange={this.updateVal}
                name='fullKit'
              />
            </td> 
            <td></td>
          </tr>
          </tbody>
        </table>
        <div className='InquiryForm__form'>
          <div className="InquiryForm__form--title">
            <h2 style={{color:'#fff'}}>訂購人信息</h2>
            <p>謝謝你的訂購。煩請填妥以下信息，稍後我們將有專人與你聯絡，發送報價並安排送貨。</p>
          </div>
          <div className='InquiryForm__form--body'>
            <TextField
              required
              fullWidth
              id="Inquiry__schoolName"
              label="學校名稱"
              margin="dense"
              error={this.state.submitError && !this.state.schoolNameValid}
              onChange={this.removePrompt}
            />
            <TextField
              required
              fullWidth
              id="Inquiry__contacts"
              label="聯絡人"
              margin="dense"
              error={this.state.submitError && !this.state.contactsValid}
              onChange={this.removePrompt}
            />
            {/*<TextField
              required
              fullWidth
              id="Inquiry__email"
              label="電郵"
              margin="dense"
            />*/}
            <FormControl
              fullWidth
              error={this.state.emailError  }
              >
              <InputLabel
                htmlFor="Inquiry__email"
                required
                >
                電郵
              </InputLabel>
              <Input
                id="Inquiry__email"
                aria-describedby="Inquiry__email"
                onChange={this.emailPrompt}
              />
              {this.state.emailError &&
                <FormHelperText id="Inquiry__email--error">
                  請輸入有效的電子郵箱
                </FormHelperText>
              }
            </FormControl>
            <TextField
              fullWidth
              id="Inquiry__telephone"
              label="聯繫電話"
              margin="dense"
            />
          </div>
          <div className="InquiryForm__form--submit">
            <Button
              name='提交'
              size='button--medium'
              onClick={this.submitForm}
              />
          </div>
        </div>
      </div>
    )
  }
}

export default InquiryForm;
