import React from 'react'
import PropTypes from 'prop-types'
//components
import TabBar from './tabBar';
import TitleMain from './titleMain';
import Video from './video';
import Image from './image';
import modules from './modules';
import Button from './button';
import Package from './package';
import FancyBtn from './fancyBtn';
//css
import '../assets/css/curriculumKit.css';
//config
import config from '../config';
//image
import  blockly from '../assets/img/curriculumKit__software--blockly.jpg';
import  cad from '../assets/img/curriculumKit__software--cad.jpg';
//data
import curriculumKit from '../data/curriculumKit';
//plugin
import scrollToComponent from 'react-scroll-to-component';
import {Link } from 'react-router-dom';


class CurriculumKit extends React.Component {
  constructor(props){
    super(props);
    this.state={
      video:curriculumKit[0].video,
      img:curriculumKit[0].img,
      name:curriculumKit[0].name,
      desc:curriculumKit[0].desc,
      cat:curriculumKit[0].cat,
      outline:curriculumKit[0].outline,
      modules:curriculumKit[0].modules,
      accessories:curriculumKit[0].accessories,
    }
  }
  componentDidMount(){
    scrollToComponent(this.scrollToTop);
  }
  render () {
    return(
      <div>
        <TitleMain name='課程中心'  ref={(section)=>{this.scrollToTop = section}} />
        <TabBar />
        <Video src={curriculumKit[this.props.id].video}/>
        <div className={`curriculumKit__content content__bgColor--${this.props.id}`}>
          <div className='curriculumKit__img'>
            <Image src={curriculumKit[this.props.id].img} alt={curriculumKit[this.props.id].name}/>
          </div>
          <div className='curriculumKit__title'>
            <h2 style={{color:'#fff'}}>{curriculumKit[this.props.id].name}</h2>
          </div>
          <div className='curriculumKit__desc'>
            <p>{curriculumKit[this.props.id].desc}</p>
          </div>
          <div className='curriculumKit__cat'>
            <p style={{backgroundColor:'#fff',color:'#000',padding:'10px 10px'}}>{curriculumKit[this.props.id].cat}</p>
          </div>
          <h3 className='title__outline content__title'>課程大綱</h3>
          <div className='curriculumKit__outline'>
            <table className='curriculumKit__table'>
              <tbody>
              {curriculumKit[this.props.id].outline.map((x,y)=>
                (<tr key={x+y}>
                  <td className='curriculumKit__td'>{x}</td>
                  <td className='curriculumKit__td'>{curriculumKit[this.props.id].outlineLesson[y]}</td>
                </tr>))
              }
              </tbody>
            </table>
          </div>
          <h3 className='title__package content__title'>內含模組與材料</h3>
          <div className='curriculumKit__package'>
            <Package modules={curriculumKit[this.props.id].modules} accessories={curriculumKit[this.props.id].accessories}/>
          </div>
          <h3 className='title__software content__title'>配套軟體</h3>
          <div className='curriculumKit__software'>
            <div className='software__blockly'>
              <img className='software__img' src={blockly} alt={curriculumKit[0].name} width='100%'/>
              <p>CocoBlockly</p>
              <a href={`${config.host}/cocoblockly/dev/`} target='_blank'><Button name='進入圖形化編程' /></a>
            </div>
            <div className='software__cad'>
              <img className='software__img' src={cad} alt={curriculumKit[0].name} width='100%'/>
              <p>TinkerCAD</p>
              <a href='https://www.tinkercad.com/'  target='_blank'><Button name='進入3D建模'/></a>
            </div>
          </div>
          <div className='curriculumKit__button'>
            <a href={curriculumKit[this.props.id].data}>
              <Button name='課程資料下載' size='button--king'/>
            </a>
          </div>
        </div>
        <div style={{margin:'200px 0'}}>
					<Link to='/service'><FancyBtn name='查看服務'/></Link>
				</div>
      </div>
    )
  }
}

export default CurriculumKit;
