import React ,{Component} from 'react';
//components
import TitleMain from './titleMain';
import TitleSub from './titleSub';
import CardMedium from './card_medium';
import Image from './image';
import CocoKit from './cocokit';
import Kit from './kit';
import TabBar from './tabBar';
import Button from '../components/button';
import FancyBtn from '../components/fancyBtn';

//css
import CurriculumImg from '../assets/img/curriculumImg.jpg'
import curriculum_theme_light from '../assets/img/curriculum_theme_light.svg'
import curriculum_theme_robot from '../assets/img/curriculum_theme_robot.svg'
import curriculum_theme_smartHome from '../assets/img/curriculum_theme_smartHome.svg'
import curriculum_theme_music from '../assets/img/curriculum_theme_music.svg'
import curriculum_program_problemSolving from '../assets/img/curriculum_program_problemSolving.svg';
import curriculum_program_combination from '../assets/img/curriculum_program_combination.svg';
import '../assets/css/curriculum.css';
//plugins
import scrollToComponent from 'react-scroll-to-component';
import nl2br from 'react-newline-to-break';
import {Link,Route} from 'react-router-dom';
//img
import section_1 from '../assets/img/curriculum_s1.png';
import section_2 from '../assets/img/curriculum_s2.png';
import section_3 from '../assets/img/curriculum_s3.png';
import section_4 from '../assets/img/curriculum_s4.png';
import section_5 from '../assets/img/curriculum_s5.png';
import kit_keyboardPiano_bg from '../assets/img/kit_keyboardPiano_bg.png';
import kit_keyboardPiano from '../assets/img/kit_keyboardPiano.png';
import kit_carLineTracking_bg from '../assets/img/kit_carLineTracking_bg.png';
import kit_carLineTracking from '../assets/img/kit_carLineTracking.png';
import kit_LEDMiniBox_bg from '../assets/img/kit_LEDMiniBox_bg.png';
import kit_LEDMiniBox from '../assets/img/kit_LEDMiniBox.png';
import kit_bipedRobotBasic_bg from '../assets/img/kit_bipedRobotBasic_bg.png';
import kit_bipedRobotBasic from '../assets/img/kit_bipedRobotBasic.png';
import kit_weatherStation_bg from '../assets/img/kit_weatherStation_bg.png';
import kit_weatherStation from '../assets/img/kit_weatherStation.png';
import kit_robotArm_bg from '../assets/img/kit_robotArm_bg.png';
import kit_robotArm from '../assets/img/kit_robotArm.png';
import video_keyboardPiano from '../assets/video/keyboardPiano.mp4';
import video_robotCarLineTracker from '../assets/video/robotCarLineTracker.mp4';
import video_LEDMiniBox from '../assets/video/LEDMiniBox.mp4';
import video_bipedRobot from '../assets/video/bipedRobot.mp4';
import video_weatherStaion from '../assets/video/weatherStation.mp4';
import video_robotArm from '../assets/video/robotArm.mp4';



const curriculum ={
	theme:{
		0:{
			content:'你是否想擁有一個屬於自己的特製創意燈? 你是否想讓自己的枱燈發出不一樣的燈光?\n\nCOCOROBO的這個課程將手把手帶你做出一盞你自己設計的專屬創意呼吸燈。針對四年級上學期的學生我們開設了創意呼吸燈課程。\n\n課程分為兩個部分，第一部分通過讓學生學習3D建模技術，讓學生們建出自己所構想的呼吸燈模型，再通過3D打印把3D模型實體化，這樣學生就可以 擁有一個獨一無二自主設計的創意呼吸燈模型。第二部分是教授學生Arduino程序設計技術，通過對LED燈等電子模組實行程序設計來達到可以隨心控制自己的呼吸燈的顏色、亮度、以及頻率。我們致力於在學生學習同時能收穫到成就感，完成一份與眾不同的產品',
		},
		1:{
			content:'擁有一個智能機械人再也不是夢！學生可利用在課堂上所學的基本電子知識，3D繪圖和程式編程技巧，製作一個載歌載舞、獨一無二的雙足機械人！我們同時提供有趣的擴增實境（AR）應用程式，讓雙足機械人化身小戰士，和你的朋友一起來一場既精彩又刺激的大戰。'
		},
		2:{
			content:'你喜歡哪一樣樂器，鋼琴還是小提琴？學習3D繪、編程與電子基本知識後，你也可以成為樂器工匠，設計與製作屬於自己的樂器！',
		},
		3:{
			content:'想了解物聯網和智能家居是怎樣工作的嗎？通過運用我們的環境感應模組，學生將可以學習如何製作一個簡單的家庭環境監測裝置，自創外形、3D打印並組裝，製作自己第一個物聯網設備。',
		},
	},
	program:{
		0:{
			content:'在專案式教學方案中，學校可以自由組合不同的項目進行課程安排。\n\n 例如：安排一個學期的課程完成巡線小車製作和雙足機器人制作，持續12周，每週2節課，共24小時',
		},
		1:{
			content:'在問題是教學方案中，我們會教授學生基本技能，引導學生思考問題，提出解決方案並實踐該解決方案。\n\n 例如：開設一門以“智能校園”為主題的問題式教學課程，我們會引導學生利用前一單元所掌握的關於「3D模型設計」、「3D打印技術」、「電子電路」，以及「程式設計」等技能，來針對校園，設計他們未來心中的智慧校園的場景。',
		}
	}
}
const kit ={
	0:{
		name:'創意電子',
		nameEN:'Creative Electronics',
		desc:'本套件將帶領學生領略聲音和燈光的美麗。利用這個套件可製作出可模擬不同樂器的電子琴。亦可以製作一個絢麗的呼吸燈。亦可將二者結合，製作出一個既可以發出聲音也可以發出燈光的樂器。',
		modules:['m0','i2','o0','o1'],
		lists:['USB數據線*1','杜邦線若干'],
		bgImg:kit_keyboardPiano_bg,
		img:kit_keyboardPiano,
		cat:'入門級套件',
		video:video_keyboardPiano,
	},
	1:{
		name:'動力小車',
		nameEN:'Robot Car',
		desc:'通過學習本套件，學生將能夠製作多功能迷你小車。小車具有自動駕駛的功能，亦可通過感知前方是否有障礙物，來實現智能避障的功能，甚至可以通過藍牙控制小車。',
		modules:['m0','o3','i0','c1','a1','a1'],
		lists:['紅外距離傳感器*2','輪胎橡膠圈*2','USB數據線*1','杜邦線若干'],
		bgImg:kit_carLineTracking_bg,
		img:kit_carLineTracking,
		cat:'中級套件',
		video:video_robotCarLineTracker,
	},
	// 2:{
	// 	name:'LED燈盒',
	// 	nameEN:'LED Mini Box',
	// 	desc:'通過簡單的3D繪圖，並經由3D打印，DIY一台獨一無二的燈盒',
	// 	modules:['m0','o1','i0'],
	// 	lists:['USB數據線*1'],
	// 	bgImg:kit_LEDMiniBox_bg,
	// 	img:kit_LEDMiniBox,
	// 	cat:'Light Kit',
	// 	video:video_LEDMiniBox,
	// },
	2:{
		name:'機械達人',
		nameEN:'Robot Guru',
		desc:'通過學習本套件，學生將能夠製作一款百變雙足機械人，可以通過設定程序使機械人作出特定的動作，除此之外，還可以通過藍牙對它進行遠程操作。通過這門課學生亦可製作一款可通過藍牙操控的機械臂。',
		modules:['m0','o4','a2','a2','c1','a3'],
		lists:['50克舵機*4','USB數據線*1','機器人組裝所需零件'],
		bgImg:kit_bipedRobotBasic_bg,
		img:kit_bipedRobotBasic,
		cat:'中級套件',
		video:video_bipedRobot,
	},
	3:{
		name:'智能家居',
		nameEN:'Smart Home Kit',
		desc:'通過學習本套件，學生將能夠製作迷你氣象站。學生可以使用迷你氣象站採集環境的温度／濕度等數據，並在熒幕上顯示出來；同時可以通過Wi-Fi控制氣象站，並實現數據的傳輸。',
		modules:['m0','c0','i5','o2','a1','a3'],
		lists:['土壤溼度感測器*1','雨滴感測器*1','時鐘模組*1','氣敏感測器*1','人體紅外感測器*1','超聲波感測器*1','火焰感測器*1','震動感測器*1','紅外接近感測器*1','傾斜感測器*1','光敏感測器*1','USB數據線*1','杜邦線若干'],
		cat:'高級套件',
		bgImg:kit_weatherStation_bg,
		img:kit_weatherStation,
		video:video_weatherStaion,
	},
	// 5:{
	// 	name:'機械臂',
	// 	nameEN:'Robot Arm',
	// 	desc:'配合3D打印的零部件，使用電子模組搭建一台簡單的機械臂',
	// 	modules:['m0','o4','a0'],
	// 	lists:['50克舵機*1','270度金屬齒輪舵機*3','USB數據線*1','機械臂組裝所需零件'],
	// 	cat:'Robot Kit',
	// 	bgImg:kit_robotArm_bg,
	// 	img:kit_robotArm,
	// 	video:video_robotArm,
	// }
}
let name=1;

class Curriculum extends Component{
	constructor(props){
		super(props);
		this.state={
			title:kit[0].name,
			video:kit[0].video,
			desc:kit[0].desc,
			name:0,
		}
		this.getCurriculumId = this.getCurriculumId.bind(this);
	}
	componentWillMount(){
		this.scrollToTop();
	}
	getCurriculumId(event){
		if(event.target.nodeName === 'BUTTON'){
			let length = event.target.className.length;
			let paramsId = event.target.className.charAt(length-1);
			this.modalState(paramsId);
			this.setState({
				name:paramsId,
			})
		}
	}
	modalState(i){
		this.setState({
			title:kit[i].name,
			video:kit[i].video,
			desc:kit[i].desc,
		})
	}
	scrollToTop(){
		window.scrollTo(0,0);
	}
	render(){
		return(
			<div className='curriculum' ref={(section)=> this.curriculum = section}>
				<TitleMain name='專題研習' />
				<div className="curriculum__section">
					<Image src={section_1}/>
					<p className="curriculum__content">專題研習可以將課題分解為一個個完整的並具有緊密關聯的學習單元，方便學生在適當的週期內進行深度探究並獲得階段性的成果反饋。</p>
				</div>
				<TitleMain name='設計思維模型' />
				<div className="curriculum__section">
					<Image src={section_2}/>
					<p className="curriculum__content">通過設計思維模型的良性循環，讓學生在學習過程中獲得解決實際問題、不斷自我更新和突破的能力。</p>
				</div>
				<div className="curriculum__section">
					<Image src={section_3}/>
				</div>
				<TitleMain name='綜合項目式學習' />
				<div className="curriculum__section">
					<Image src={section_4}/>
					<p className="curriculum__content">以學生為中心，分組進行團隊合作</p>
				</div>
				<TitleMain name='課程特色' />
				<div className="curriculum__section">
					<Image src={section_5}/>
				</div>
				<TitleMain name='課程套件' />
				<div>
					<TabBar fn={this.getCurriculumId}/>
					{/*<Kit
					key={kit[this.state.name].name}
					desc={kit[this.state.name].desc}
					modules={kit[this.state.name].modules}
					lists={kit[this.state.name].lists}
					bgImg={kit[this.state.name].bgImg}
					img={kit[this.state.name].img}
					name={kit[this.state.name].name}
					nameEN={kit[this.state.name].nameEN}
					cat={kit[this.state.name].cat}
					video={kit[this.state.name].video}
					/>*/}
				</div>
				{/*
				<div id='modal-video' uk-modal='true' className='uk-flex-top'>
					<div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
						<h2>{this.state.title}</h2>
						<p>{this.state.desc}</p>
						<video controls='true' playsInline uk-video='true' width="100%" src={this.state.video}></video>
						<button className="uk-modal-close-outside"  uk-icon='close'>X</button>
					</div>
				</div>
				*/}
				{/*
					<div>
					<p className='content'>我們的課程體系融合了3D打印技術、配合CocoRobo自主研發的電子模組和圖形化程序設計界面，學生可以從四大主題出發，製作出千變萬化的機械人和智能裝置。<br></br><br></br><br></br>
					我們的教學理念是以「Project based learning(PBL)」為核心。基於項目的學習(PBL)是以學生為中心的 教學法，涉及一種動態的課堂教學方式，相信學生通過積極探索現實世界的挑戰和問題，獲得更深入的知 識。學生通過長時間工作來學習一個主題，以調查和響應一個複雜的課程或問題。這是一種積極學習和探 究式學習的風格PBL與以紙為基礎的記憶或教師主導的指令式教育不同，只是提出既定的事實，或者通過 改變問題或場景來描繪流暢的知識路徑。結合該理念，我們設計了許多不同類型的課程，方便不同年齡段 的學生深入學習各種知識結構。<br></br><br></br><br></br>
					我們希望通過這種課程體系，為STEAM教育提供最好的一體化方案。激發學生們的學習動力，讓他們積極 主動的去接觸與瞭解知識。將不同學科的知識結合，強調個性化教育。讓每個學生的產品都富有自己的特色。 達到教學統一化，成果個性化的效果。
					</p>
				</div>
				<TitleSub name='課程主題' margin='50px 10%'/>
				<CardMedium img={curriculum_theme_light} content={nl2br(curriculum.theme[0].content)} title='燈光'/>
				<CardMedium img={curriculum_theme_robot} content={nl2br(curriculum.theme[1].content)} title='機器人'/>
				<CardMedium img={curriculum_theme_music} content={nl2br(curriculum.theme[2].content)} title='音樂'/>
				<CardMedium img={curriculum_theme_smartHome} content={nl2br(curriculum.theme[3].content)} title='智能家居'/>

				<TitleSub name='課程方案' margin='50px 10%'/>
				<CardMedium img={curriculum_program_combination} content={nl2br(curriculum.program[0].content)} title='專案式教學'/>
				<CardMedium img={curriculum_program_problemSolving} content={nl2br(curriculum.program[1].content)} title='問題式教學'/>
				*/}
				<div style={{margin:'200px 0'}}>
					<Link to='/service'><FancyBtn name='查看服務'/></Link>
				</div>
			</div>
		)
	}
}
export default Curriculum;
