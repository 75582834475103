import React from 'react'
import PropTypes from 'prop-types'
import TitleMain from '../components/titleMain';
import ComingSoon from '../components/comingSoon';

class Tutor extends React.Component {
  render () {
    return(
      <div className="Tutor">
        <div className="Tutor__title">
          <TitleMain name='在線教學'/>
        </div>
        <ComingSoon/>
      </div>
    )
  }
}

export default Tutor;
